import axios from '@/plugins/axios';

export default {

   /**
   * Exporta participantes de una convención en CSV
   * @param {number} conventionId - ID de la convención
   * @returns {Promise} - Promise con el archivo para descargar
   */
   exportConventionParticipants(conventionId) {
    return axios.get(`/conventions/${conventionId}/export-convention-participants`, {
      responseType: 'blob',
      headers: {
        'Accept': 'text/csv'
      }
    });
  },
  /**
   * Obtiene lista de convenciones con paginación y filtros
   * @param {Object} params - Parámetros de búsqueda y ordenación
   * @param {string} [params.search] - Término de búsqueda
   * @param {string} [params.orderBy] - Campo para ordenar
   * @param {string} [params.orderByMethod] - Método de ordenación (asc/desc)
   * @param {number} [params.perPage] - Items por página
   * @returns {Promise} - Promise con la respuesta de la API
   */
  getConventions(params = {}) {
    const {
      search = null,
      orderBy = null,
      orderByMethod = null,
      perPage = null
    } = params;

    return axios.get('/conventions', {
      params: {
        search,
        orderBy,
        orderByMethod,
        perPage
      }
    });
  }
};