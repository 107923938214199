<template>
  <div class="container p-4">
    <div class="d-flex justify-content-between align-items-center mb-3 header">
      <h1 class="mb-0 title">Areas</h1>
      <div>
        <button @click="openModal(null)" class="btn btn-primary">Nueva Area</button>
      </div>
    </div>

    <div class="mb-3">
      <input
        type="text"
        v-model="searchTerm"
        class="form-control"
        placeholder="Buscar..."
      />
    </div>

    <div v-if="loading" class="text-center">Cargando...</div>
    <div v-else>
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-responsive-md">
          <thead class="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="area in areas" :key="area.id">
              <td>{{ area.name }}</td>
              <td>{{ area.status === 1 ? "Activo" : "Inactivo" }}</td>
              <td class="text-center">
                <button
                  @click="editArea(area)"
                  class="btn btn-sm btn-outline-primary mr-2 action-button"
                >
                  Editar
                </button>
                <button
                  @click="deleteAreaById(area.id)"
                  class="btn btn-sm btn-outline-danger action-button"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="lastPage > 1" class="pagination-container d-flex justify-content-end">
        <button @click="fetchAreas(1)" class="btn btn-sm btn-outline-secondary" :disabled="currentPage === 1">
          Primera
        </button>
        <button @click="fetchAreas(currentPage - 1)" class="btn btn-sm btn-outline-secondary" :disabled="currentPage === 1">
          Anterior
        </button>
        <button @click="fetchAreas(currentPage + 1)" class="btn btn-sm btn-outline-secondary" :disabled="!hasMorePages">
          Siguiente
        </button>
        <button @click="fetchAreas(lastPage)" class="btn btn-sm btn-outline-secondary" :disabled="currentPage === lastPage">
          Última
        </button>
      </div>
    </div>

    <ModalFormArea
      :isOpen="modalOpen"
      :area="selectedArea"
      @close="modalOpen = false"
      @success="handleModalSuccess"
    />
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { fetchAreasApi, deleteAreasApi } from "@/services/api/areaService.js";
import ModalFormArea from "@/components/area/ModalFormArea.vue";
import userConfirmation from "@/utils/userConfirmation.js";

export default {
  components: {
    ModalFormArea,
  },
  setup() {
    const areas = ref([]);
    const loading = ref(false);
    const searchTerm = ref("");
    const orderByMethod = ref("asc");
    const perPage = ref(10);
    const status = ref(null);
    const modalOpen = ref(false);
    const selectedArea = ref(null);
    const currentPage = ref(1);
    const lastPage = ref(1);
    const hasMorePages = ref(false);

    const fetchAreas = async (page = 1) => {
      loading.value = true;
      try {
        const data = await fetchAreasApi({
          search: searchTerm.value,
          orderByMethod: orderByMethod.value,
          perPage: perPage.value,
          status: status.value,
          page,
        });
        areas.value = data.data || [];
        currentPage.value = data.meta.current_page;
        lastPage.value = data.meta.last_page;
        hasMorePages.value = data.meta.current_page < data.meta.last_page;
      } catch (error) {
        console.error("Error fetching areas:", error);
      } finally {
        loading.value = false;
      }
    };

    watch([searchTerm, orderByMethod, perPage, status], () => {
      fetchAreas(1);
    });

    const openModal = (area) => {
      selectedArea.value = area;
      modalOpen.value = true;
    };

    const handleModalSuccess = () => {
      modalOpen.value = false;
      fetchAreas(currentPage.value);
    };

    const editArea = (area) => {
      openModal(area);
    };

    const deleteAreaById = async (id) => {
      const confirmed = await userConfirmation(
        `¿Está seguro de eliminar el área seleccionada?`
      );
      if (!confirmed) return;
      try {
        await deleteAreasApi(id);
        fetchAreas(currentPage.value);
      } catch (error) {
        console.error("Error deleting area:", error);
      }
    };

    onMounted(() => {
      fetchAreas();
    });

    return {
      areas,
      loading,
      searchTerm,
      fetchAreas,
      modalOpen,
      selectedArea,
      openModal,
      handleModalSuccess,
      editArea,
      deleteAreaById,
      currentPage,
      lastPage,
      hasMorePages,
      orderByMethod,
      perPage,
      status,
    };
  },
};
</script>

<style scoped>
.header .title {
  font-size: 1.5rem;
}

.table-responsive-md {
  overflow-x: auto;
}

.table-bordered {
  width: 100%;
  border: 1px solid #dee2e6;
}

.thead-dark th {
  background-color: #343a40;
  color: #fff;
}

.table th,
.table td {
  padding: 12px;
  font-size: 0.875rem;
}

.pagination-container {
  gap: 8px;
}

.action-button {
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .header .title {
    font-size: 1.25rem;
  }

  .table th,
  .table td {
    padding: 8px;
    font-size: 0.75rem;
  }

  .pagination-container {
    flex-direction: column;
    gap: 6px;
    align-items: center;
  }

  .btn {
    font-size: 0.75rem;
    padding: 5px 10px;
  }

  .action-button {
    font-size: 0.75rem;
  }
}
</style>
