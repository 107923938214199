import axios from "@/plugins/axios";

export async function fetchUserViews(userId) {
    try {
        const response = await axios.get(`/concessionaire-users/${userId}/views`);
        return response.data.data || [];
    } catch (error) {
        console.error("Error al obtener las vistas del usuario:", error);
        throw error;
    }
}

export async function syncUserViews(userId, views) {
    try {
        const response = await axios.post(`/concessionaire-users/${userId}/sync-views`, {
            views,
        });
        return response.data;
    } catch (error) {
        console.error("Error al sincronizar las vistas del usuario:", error);
        throw error;
    }
}


export async function exportUsers() {
    try {
      const response = await axios.get('/concessionaire-users/export-users', {
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      console.error("Error al exportar usuarios:", error);
      throw error;
    }
  }