<template>
  <div class="conference-view container mt-4">
    <div class="mb-4">
      <button class="btn btn-link text-decoration-none" @click="goBack">
        <i class="bi bi-arrow-left-circle-fill h3 text-primary"></i>
      </button>
    </div>
    <h1 class="text-center mb-4 display-6">Jornadas</h1>

    <div
      v-if="conferences.length > 0"
      class="table-responsive rounded-3 shadow-sm"
    >
      <table class="table table-hover table-borderless mb-0">
        <thead class="table-light bg-primary bg-opacity-10">
          <tr>
            <th scope="col" class="ps-4 py-3 fw-semibold">Nombre</th>
            <th scope="col" class="py-3 fw-semibold">Hora Inicio</th>
            <th scope="col" class="py-3 fw-semibold">Hora Fin</th>
            <th scope="col" class="py-3 fw-semibold">Descripción</th>
            <th scope="col" class="py-3 fw-semibold text-center">
              Participantes
            </th>
            <th scope="col" class="pe-4 py-3 fw-semibold text-center">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody class="border-top-0">
          <tr
            v-for="conference in conferences"
            :key="conference.id"
            class="align-middle transition-all"
          >
            <td class="ps-4">
              <span class="fw-medium text-primary">{{ conference.name }}</span>
            </td>
            <td>
              <span class="badge bg-light text-dark border">
                {{ formatTime(conference.start_time) }}
              </span>
            </td>
            <td>
              <span class="badge bg-light text-dark border">
                {{ formatTime(conference.end_time) }}
              </span>
            </td>
            <td class="text-secondary" style="max-width: 300px">
              <div class="text-truncate">
                {{ conference.description || "Sin descripción" }}
              </div>
            </td>
            <td class="text-center">
              <span
                class="badge rounded-pill bg-info bg-opacity-25 text-info fs-6"
                :title="`${conference.participants_count} participantes registrados`"
              >
                {{ conference.participants_count }}
              </span>
            </td>
            <td class="pe-4 text-center">
              <div class="d-flex gap-2 justify-content-center">
                <button
                  class="btn btn-sm btn-icon btn-hover-light"
                  @click="showParticipants(conference)"
                  title="Ver inscriptos"
                >
                  <i class="bi bi-people-fill text-primary fs-5"></i>
                </button>
                <button
                  class="btn btn-sm btn-icon btn-hover-light"
                  @click="handleExport(conference)"
                  title="Exportar participantes"
                  :disabled="conference.participants_count === 0"
                >
                  <i class="bi bi-download text-success fs-5"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="alert alert-info text-center">
      No se encontraron jornadas registradas
    </div>
    <ParticipantsModal
      :participants="selectedParticipants"
      :show="showParticipantsModal"
      @close="showParticipantsModal = false"
    />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import ParticipantsModal from "./components/ParticipantsModal.vue";
import { useExporter } from '@/composables/useExporter';
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import conferenceService from "@/services/api/conference";
import Swal from "sweetalert2";

const store = useStore();
const router = useRouter();

const selectedParticipants = ref([]);
const showParticipantsModal = ref(false);

const { handleExport: exportData } = useExporter();

const conferences = computed(() => store.state.conferencesData || []);

const goBack = () => {
  router.push({ name: "Convention" });
};

const formatTime = (timeString) => {
  if (!timeString) return "--:--";
  return timeString.slice(0, 5);
};

const showParticipants = async (conference) => {
  try {
    const response = await conferenceService.getConferenceParticipants(
      conference.id
    );

    selectedParticipants.value = response.data.data;

    if (selectedParticipants.value.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Sin inscriptos",
        text: "Esta jornada no tiene participantes registrados",
        confirmButtonColor: "#3085d6",
      });
      showParticipantsModal.value = false;
    } else {
      showParticipantsModal.value = true;
    }
  } catch (error) {
    console.error("Error obteniendo participantes:", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "No se pudieron cargar los participantes",
      confirmButtonColor: "#d33",
    });
  }
};

const handleExport = async (conference) => {
  if (conference.participants_count === 0) {
    Swal.fire({
      icon: 'info',
      title: 'Sin participantes',
      text: 'No hay datos para exportar',
      confirmButtonColor: '#3085d6',
    });
    return;
  }

  try {
    await exportData(
      () => conferenceService.exportConferenceParticipants(conference.id),
      `participantes-${conference.name}.csv`
    );

  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error en la exportación',
      text: 'No se pudo generar el archivo de exportación',
      confirmButtonColor: '#d33',
    });
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.conference-view {
  max-width: 1200px;
  margin: 0 auto;
}

.table {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
}

.table th {
  white-space: nowrap;
  background-color: #f8f9fa;
  padding: 1rem;
}

.table td {
  vertical-align: middle;
  padding: 0.75rem;
}

.table-hover tbody tr:hover {
  background-color: #f8f9fa;
  transform: translateX(2px);
  transition: all 0.2s ease;
}

.btn-hover-light:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.text-truncate {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>