<template>
  <div class="modal fade show d-block" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">
            <i class="fas fa-info-circle me-2"></i>
            Detalles del Concesionario
          </h5>
          <button
            type="button"
            class="btn-close btn-close-white"
            @click="closeModal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <div v-if="concessionaire" class="container-fluid">
            <div class="row mb-4">
              <div class="col-md-6">
                <h5 class="text-primary mb-3">
                  <i class="fas fa-building me-2"></i>Información Principal
                </h5>
                <dl class="row">
                  <dt v-if="concessionaire.central_house" class="col-sm-4">Sucursal:</dt>
                  <dt v-else class="col-sm-4">Razón Social:</dt>
                  <dd class="col-sm-8">
                    {{ concessionaire.business_name || "N/A" }}
                  </dd>

                  <dt class="col-sm-4">CUIT:</dt>
                  <dd class="col-sm-8">{{ concessionaire.cuit || "N/A" }}</dd>

                  <dt class="col-sm-4">Tipo:</dt>
                  <dd class="col-sm-8">
                    {{
                      getConcessionaireTypeLabel(concessionaire.type) || "N/A"
                    }}
                  </dd>
                </dl>
              </div>

              <div class="col-md-6">
                <h5 class="text-primary mb-3">
                  <i class="fas fa-address-card me-2"></i>Contacto
                </h5>
                <dl class="row">
                  <dt class="col-sm-4">Dirección:</dt>
                  <dd class="col-sm-8">
                    {{ fullAddress || "N/A" }}
                  </dd>

                  <dt class="col-sm-4">Teléfono:</dt>
                  <dd class="col-sm-8">{{ concessionaire.phone || "N/A" }}</dd>

                  <dt class="col-sm-4">Email:</dt>
                  <dd class="col-sm-8">{{ concessionaire.email || "N/A" }}</dd>
                </dl>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h5 class="text-primary mb-3">
                  <i class="fas fa-car me-2"></i>Información Comercial
                </h5>
                <dl class="row">
                  <dt class="col-sm-4">Rubro:</dt>
                  <dd class="col-sm-8">
                    {{ getVehicleTypeName(concessionaire.sector) || "N/A" }}
                  </dd>

                  <dt class="col-sm-4">Marcas:</dt>
                  <dd class="col-sm-8">
                    {{ getBrandNames(concessionaire.brands) || "N/A" }}
                  </dd>
                </dl>
              </div>

              <div class="col-md-6">
                <h5 class="text-primary mb-3">
                  <i class="fas fa-map-marker-alt me-2"></i>Ubicación
                </h5>
                <dl class="row">
                  <dt class="col-sm-4">Provincia:</dt>
                  <dd class="col-sm-8">
                    {{ concessionaire.province_name || "N/A" }}
                  </dd>

                  <dt class="col-sm-4">Localidad:</dt>
                  <dd class="col-sm-8">
                    {{ concessionaire.locality_name || "N/A" }}
                  </dd>

                  <dt class="col-sm-4">Regional:</dt>
                  <dd class="col-sm-8">
                    {{ concessionaire.regional_name || "N/A" }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div v-if="concessionaire.central_house" class="row mt-4">
            <div class="col-12">
              <h5 class="text-primary mb-3">
                <i class="fas fa-home me-2"></i>Casa Central
              </h5>
              <div class="row">
                <div class="col-md-6">
                  <dl class="row">
                    <dt class="col-sm-4">Razón Social:</dt>
                    <dd class="col-sm-8">
                      {{ concessionaire.central_house.business_name || "N/A" }}
                    </dd>

                    <dt class="col-sm-4">CUIT:</dt>
                    <dd class="col-sm-8">
                      {{ concessionaire.central_house.cuit || "N/A" }}
                    </dd>

                    <dt class="col-sm-4">Rubro:</dt>
                    <dd class="col-sm-8">
                      {{
                        getVehicleTypeName(
                          concessionaire.central_house.sector
                        ) || "N/A"
                      }}
                    </dd>

                    <dt class="col-sm-4">Marcas:</dt>
                    <dd class="col-sm-8">
                      {{
                        getBrandNames(concessionaire.central_house.brands) ||
                        "N/A"
                      }}
                    </dd>
                  </dl>
                </div>

                <div class="col-md-6">
                  <dl class="row">
                    <dt class="col-sm-4">Dirección:</dt>
                    <dd class="col-sm-8">
                      {{ centralHouseAddress || "N/A" }}
                    </dd>

                    <dt class="col-sm-4">Teléfono:</dt>
                    <dd class="col-sm-8">
                      {{ concessionaire.central_house.phone || "N/A" }}
                    </dd>

                    <dt class="col-sm-4">Email:</dt>
                    <dd class="col-sm-8">
                      {{ concessionaire.central_house.email || "N/A" }}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { computed } from "vue";
import { useVehicleTypes } from "@/composables/useVehicleTypes";
import { getConcessionaireTypeLabel } from "@/constants/concessionaireTypes.js";

export default {
  name: "ConcessionaireDetailsModal",
  props: {
    concessionaire: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getVehicleTypeName } = useVehicleTypes();

    const fullAddress = computed(() => {
      if (!props.concessionaire) return "";
      const { street_name, street_number, postal_code } = props.concessionaire;
      return [street_name, street_number, postal_code]
        .filter(Boolean)
        .join(", ");
    });

    const getBrandNames = (brands) => {
      if (!brands || brands.length === 0) return "N/A";
      return brands.map((b) => b.brand_name).join(", ");
    };

    const centralHouseAddress = computed(() => {
      if (!props.concessionaire?.central_house) return "";
      const { street_name, street_number, postal_code } =
        props.concessionaire.central_house;
      return [street_name, street_number, postal_code]
        .filter(Boolean)
        .join(", ");
    });

    const closeModal = () => {
      emit("close");
    };

    return {
      centralHouseAddress,
      fullAddress,
      getVehicleTypeName,
      getBrandNames,
      getConcessionaireTypeLabel,
      closeModal,
    };
  },
};
</script>
  
  <style scoped>
.modal-content {
  border-radius: 0.8rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.modal-header {
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 1.2rem 1.5rem;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
}

dt {
  font-weight: 500;
  color: #6c757d;
}

dd {
  color: #495057;
  font-weight: 400;
}
</style>