import { ref } from "vue";
import { fetchConcessionairesApi } from "@/services/api/concessionaireService.js";
import { getConcessionaireTypeValue } from "@/constants/concessionaireTypes.js";

export function useConcessionaire() {
  const concessionaires = ref([]);
  const loading = ref(false);
  const error = ref(null);
  const currentPage = ref(1);
  const lastPage = ref(1);
  const hasMorePages = ref(false);
  const totalRegisters = ref(0);
  const totalWithoutAdministrators = ref(0);
  const totalWithoutusers = ref(0);

  const fetchConcessionaires = async (params = {}) => {
    const {
      subMenuLabel = "",
      page = 1,
      perPage = 15,
      search = "",
      province = null,
      locality = null,
      regional = null,
      sector = null,
      brand = null,
      withoutUsers = null,
      withoutAdministrators = null,
    } = params;

    loading.value = true;
    concessionaires.value = [];
    error.value = null;
    try {
      const subMenu = getConcessionaireTypeValue(subMenuLabel);
      if (!subMenu) {
        throw new Error(`SubMenu no válido: ${subMenuLabel}`);
      }
      const response = await fetchConcessionairesApi({
        page,
        perPage,
        search,
        type: subMenu,
        province_id: province || null,
        locality_id: locality || null,
        regional_id: regional || null,
        sector: sector || null,
        brand_id: brand || null,
        withoutUsers: withoutUsers,
        withoutAdministrators: withoutAdministrators
      });
      if (response.data?.data) {
        concessionaires.value = response.data.data;
        currentPage.value = response.data.meta?.current_page || 1;
        lastPage.value = response.data.meta?.last_page || 1;
        hasMorePages.value = response.data.meta?.current_page < response.data.meta?.last_page;
        totalRegisters.value = response.data.meta?.registers || 0;
        totalWithoutAdministrators.value = response.data.meta?.without_administrators || 0;
        totalWithoutusers.value = response.data.meta?.without_users || 0;
      } else {
        throw new Error(response.data?.message || "Error desconocido.");
      }
    } catch (err) {
      error.value = err.response?.data?.message || err.message || "Error desconocido.";
      concessionaires.value = [];
    } finally {
      loading.value = false;
    }
  };

  return {
    concessionaires,
    loading,
    error,
    currentPage,
    lastPage,
    hasMorePages,
    totalRegisters,
    totalWithoutAdministrators,
    totalWithoutusers,
    fetchConcessionaires,
  };
}