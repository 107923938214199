<template>
  <div class="convention-view container mt-4">
    <h1 class="text-center mb-4 display-6 fw-bold text-primary">
      Convenciones
    </h1>

    <div v-if="isLoading" class="text-center p-5">
      <div
        class="spinner-border text-primary"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="visually-hidden">Cargando...</span>
      </div>
      <p class="mt-3 text-muted">Cargando convenciones...</p>
    </div>

    <div
      v-else-if="conventions.length > 0"
      class="table-responsive rounded-3 shadow-sm"
    >
      <table class="table table-hover table-borderless mb-0">
        <thead class="table-light bg-primary bg-opacity-10">
          <tr>
            <th scope="col" class="ps-4 py-3 fw-semibold">Nombre</th>
            <th scope="col" class="py-3 fw-semibold">Lugar</th>
            <th scope="col" class="py-3 fw-semibold">Inicio</th>
            <th scope="col" class="py-3 fw-semibold">Fin</th>
            <th scope="col" class="py-3 fw-semibold">Regionales</th>
            <th scope="col" class="pe-4 py-3 fw-semibold text-center">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody class="border-top-0">
          <tr
            v-for="convention in conventions"
            :key="convention.id"
            class="align-middle transition-all"
          >
            <td class="ps-4">
              <span class="fw-medium text-dark">{{ convention.name }}</span>
            </td>
            <td>
              <span class="badge bg-light text-dark border">
                {{ convention.place || "No especificado" }}
              </span>
            </td>
            <td>
              <div class="d-flex flex-column">
                <span class="text-dark">{{
                  formattedDate(convention.start_datetime)
                }}</span>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column">
                <span class="text-dark">{{
                  formattedDate(convention.end_datetime)
                }}</span>
              </div>
            </td>
            <td class="text-secondary" style="max-width: 200px">
              <div
                class="text-truncate"
                :title="formatRegionals(convention.regionals)"
              >
                {{ formatRegionals(convention.regionals) || "No especificado" }}
              </div>
            </td>
            <td class="pe-4 text-center">
              <div class="d-flex gap-2 justify-content-center">
                <button
                  class="btn btn-sm btn-icon btn-hover-light"
                  title="Cantidad de inscriptos"
                  style="cursor: default"
                  tabindex="-1"
                >
                  <i class="bi bi-people text-primary fs-5"></i>
                  <span
                    class="ms-2 badge bg-primary bg-opacity-25 text-primary"
                  >
                    {{ convention.participants_count || 0 }}
                  </span>
                </button>
                <button
                  class="btn btn-sm btn-icon btn-hover-light"
                  @click="showConferences(convention.conferences)"
                  :title="`Ver ${convention.conferences?.length || 0} jornadas`"
                >
                  <i class="bi bi-calendar-event text-primary fs-5"></i>
                  <span
                    class="ms-2 badge bg-primary bg-opacity-25 text-primary"
                  >
                    {{ convention.conferences?.length || 0 }}
                  </span>
                </button>
                <button
                  class="btn btn-sm btn-icon btn-hover-light"
                  @click="handleExport(convention)"
                  title="Exportar participantes del evento"
                >
                  <i class="bi bi-download text-success fs-5"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="alert alert-info text-center rounded-3 shadow-sm">
      <i class="bi bi-info-circle me-2"></i>No se encontraron convenciones
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import conventionService from "@/services/api/convention";
import { useExporter } from "@/composables/useExporter";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

const isLoading = ref(true);
const conventions = ref([]);

const { handleExport: exportData } = useExporter();

const formattedDate = (dateString) => {
  if (!dateString) return "No especificado";
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(dateString).toLocaleDateString("es-ES", options);
};

const formatRegionals = (regionals) => {
  if (!regionals || !regionals.length) return "Sin regionales";

  const regionalNames = regionals.map((regional) => regional.name);
  return regionalNames.join(", ");
};

const fetchConventions = async () => {
  try {
    const response = await conventionService.getConventions();
    conventions.value = response.data.data;
  } catch (error) {
    console.error("Error obteniendo convenciones:", error);
  } finally {
    isLoading.value = false;
  }
};

const showConferences = (conferences) => {
  store.dispatch("updateConferencesData", conferences);
  router.push({ name: "Conference" });
};

const handleExport = async (convention) => {
  try {
    const filename = await exportData(
      () => conventionService.exportConventionParticipants(convention.id),
      `participantes-${convention.place || "evento"}.csv`
    );

    Swal.fire({
      icon: "success",
      title: "Exportación exitosa",
      text: `El archivo ${filename} se ha descargado correctamente`,
      confirmButtonColor: "#3085d6",
    });
  } catch (error) {
    console.error("Error exportando participantes:", error);
    Swal.fire({
      icon: "error",
      title: "Error en la exportación",
      text: "No se pudo generar el archivo de participantes",
      confirmButtonColor: "#d33",
    });
  }
};

onMounted(fetchConventions);
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.convention-view {
  max-width: 1200px;
  margin: 0 auto;
}

.table {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.table th {
  white-space: nowrap;
}

.convention-view {
  max-width: 1200px;
  margin: 0 auto;
}

h1.display-6 {
  font-weight: 600;
  letter-spacing: -0.5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 52px !important;
  padding-top: 23px;
}
</style>