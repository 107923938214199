<template>
  <div
    class="modal fade"
    tabindex="-1"
    :class="{ show: showModal }"
    style="display: block; background-color: rgba(0, 0, 0, 0.5)"
    v-if="showModal"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content border-0 shadow-lg">
        <div class="modal-header bg-primary bg-opacity-10">
          <h5 class="modal-title fw-semibold text-primary">
            <i class="bi bi-people-fill me-2"></i>Participantes de la Jornada
          </h5>
          <button 
            type="button" 
            class="btn-close" 
            @click="closeModal"
            style="padding: 0.75rem;"
          ></button>
        </div>
        <div class="modal-body p-0">
          <div class="table-responsive scrollable-modal-body">
            <table class="table table-hover table-borderless mb-0">
              <thead class="sticky-top bg-light">
                <tr class="text-uppercase small text-secondary">
                  <th class="px-4 py-3 fw-semibold">Nombre</th>
                  <th class="py-3 fw-semibold">Email</th>
                  <th class="pe-4 py-3 fw-semibold text-end">Inscripción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="participant in participants" :key="participant.id">
                  <td class="px-4">
                    <span class="d-inline-block text-truncate" style="max-width: 200px;">
                      {{ participant.name }} {{ participant.lastname || "" }}
                    </span>
                  </td>
                  <td>
                    <span class="d-inline-block text-truncate" style="max-width: 250px;">
                      {{ participant.email }}
                    </span>
                  </td>
                  <td class="pe-4 text-end">
                    <span class="badge bg-light text-dark border">
                      {{ formatDate(participant.registration_date) }}
                    </span>
                  </td>
                </tr>
                <tr v-if="participants.length === 0">
                  <td colspan="3" class="text-center text-muted py-4">
                    <i class="bi bi-info-circle me-2"></i>No hay participantes para mostrar
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer bg-light">
          <button 
            type="button" 
            class="btn btn-sm btn-secondary px-4"
            @click="closeModal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";

const props = defineProps({
  participants: {
    type: Array,
    default: () => [],
  },
});

const showModal = ref(false);

const formatDate = (dateString) => {
  if (!dateString) return "";
  const [datePart] = dateString.split(" ");
  const [year, month, day] = datePart.split("-");
  return `${day}-${month}-${year}`;
};

watch(
  () => props.participants,
  (newVal) => {
    showModal.value = newVal.length > 0;
  }
);

const closeModal = () => {
  showModal.value = false;
};
</script>

<style scoped>
.modal {
  backdrop-filter: blur(3px);
}

.modal-content {
  border-radius: 0.5rem;
}

.scrollable-modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.sticky-top {
  top: -1px;
  z-index: 1;
}

.table-hover tbody tr:hover {
  background-color: #f8f9fa;
}

.badge {
  padding: 0.35em 0.65em;
  font-size: 0.85em;
  letter-spacing: 0.05em;
}
</style>