<template>
  <div>
    <div class="container p-4">
      <ConcessionaireHeader
        :current-concessionaire-type="currentConcessionaireType"
        :total-registers="totalRegisters"
        :total-without-administrators="totalWithoutAdministrators"
        :total-without-users="totalWithoutusers"
        :without-users="withoutUsers"
        :without-administrators="withoutAdministrators"
        @update:withoutUsers="(newValue) => (withoutUsers = newValue)"
        @update:withoutAdministrators="
          (newValue) => (withoutAdministrators = newValue)
        "
      />
      <div class="d-flex justify-content-end mb-4">
        <button
          v-if="currentConcessionaireTypeValue === 1"
          @click="modalNew"
          class="btn btn-primary add-button"
        >
          AGREGAR NUEVO <i class="fas fa-plus ml-2"></i>
        </button>
      </div>

      <ConcessionaireDropdownFilters
        :selected-province="selectedProvince"
        :provinces-options="provincesOptions"
        :loading-provinces="loadingProvinces"
        @update:selected-province="selectedProvince = $event"
        :selected-locality="selectedLocality"
        :localities-options="localitiesOptions"
        :loading-localities="loadingLocalities"
        @update:selected-locality="selectedLocality = $event"
        :selected-regional="selectedRegional"
        :regionals-options="regionalsOptions"
        :loading-regionals="loadingRegionals"
        @update:selected-regional="selectedRegional = $event"
        :selected-brand="selectedBrand"
        :brands-options="brandsOptions"
        :loading-brands="loadingBrands"
        @update:selected-brand="selectedBrand = $event"
      />

      <div class="search-bar mb-3">
        <input
          type="text"
          v-model="searchTerm"
          class="form-control"
          placeholder="Buscar..."
        />
      </div>

      <div class="pagination-info mb-2">
        <span>Página {{ currentPage }} de {{ lastPage }}</span>
      </div>

      <div v-if="loading" class="text-center">Cargando...</div>
      <div v-else>
        <div v-if="concessionaires.length === 0" class="text-center">
          No hay datos disponibles.
        </div>
        <div v-else class="table-responsive">
          <table ref="table" class="table table-striped table-formal">
            <thead>
              <tr>
                <th>CUIT</th>
                <th>Razón Social</th>
                <th>Provincia</th>
                <th>Localidad</th>
                <th>Regional</th>
                <th>Calle</th>
                <th>Rubro</th>
                <th>Marca</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="concessionaire in concessionaires"
                :key="concessionaire.id"
              >
                <td>{{ concessionaire.cuit || "N/A" }}</td>
                <td>
                  <template
                    v-if="
                      concessionaire.type !== 1 && concessionaire.central_house
                    "
                  >
                    {{ concessionaire.central_house.business_name }} -
                    {{ concessionaire.business_name }}
                  </template>
                  <template v-else>
                    {{ concessionaire.business_name || "Nombre no disponible" }}
                  </template>
                </td>
                <td>{{ concessionaire.province_name || "N/A" }}</td>
                <td>{{ concessionaire.locality_name || "N/A" }}</td>
                <td>{{ concessionaire.regional_name || "N/A" }}</td>
                <td>{{ concessionaire.street_name || "N/A" }}</td>
                <td>{{ getVehicleTypeName(concessionaire.sector) }}</td>
                <td>{{ getBrandNames(concessionaire.brands) }}</td>
                <td
                  :class="
                    concessionaire.status === 1
                      ? 'status-enabled'
                      : 'status-disabled'
                  "
                >
                  {{
                    concessionaire.status === 1 ? "Habilitado" : "Inhabilitado"
                  }}
                </td>

                <td class="text-center position-relative">
                  <div class="dropdown">
                    <button
                      class="btn btn-link p-0"
                      type="button"
                      @click.stop="toggleDropdown(concessionaire.id)"
                      :aria-expanded="dropdownVisible === concessionaire.id"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <ul
                      v-if="dropdownVisible === concessionaire.id"
                      class="dropdown-menu show"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="editConcessionaire(concessionaire)"
                        >
                          <i class="fas fa-edit"></i> Editar
                        </a>
                      </li>
                      <li v-if="concessionaire.type !== 2">
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="addBranch(concessionaire)"
                        >
                          <i class="fas fa-plus"></i> Agregar Sucursal
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item text-danger"
                          href="#"
                          @click.prevent="
                            disableConcessionaireHandler(concessionaire)
                          "
                        >
                          <i
                            :class="
                              concessionaire.status === 1
                                ? 'fas fa-ban'
                                : 'fas fa-check-circle'
                            "
                          ></i>
                          {{
                            concessionaire.status === 1
                              ? "Inhabilitar"
                              : "Habilitar"
                          }}
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="viewUsers(concessionaire)"
                        >
                          <i class="fas fa-users"></i> Ver usuarios
                        </a>
                      </li>
                      <li v-if="concessionaire.type !== 2">
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="viewBranch(concessionaire)"
                        >
                          <i class="fas fa-folder-open"></i> Ver sucursales
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="viewMoreInfo(concessionaire)"
                        >
                          <i class="fas fa-info-circle"></i> Más información
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pagination d-flex justify-content-end mt-3">
          <button
            @click="loadConcessionaires(1)"
            class="btn btn-sm btn-outline-secondary mr-2"
            :disabled="currentPage === 1"
          >
            Primera
          </button>
          <button
            @click="loadConcessionaires(currentPage - 1)"
            class="btn btn-sm btn-outline-secondary mr-2"
            :disabled="currentPage === 1"
          >
            Anterior
          </button>
          <button
            @click="loadConcessionaires(currentPage + 1)"
            class="btn btn-sm btn-outline-secondary mr-2"
            :disabled="!hasMorePages"
          >
            Siguiente
          </button>
          <button
            @click="loadConcessionaires(lastPage)"
            class="btn btn-sm btn-outline-secondary"
            :disabled="currentPage === lastPage"
          >
            Última
          </button>
        </div>
      </div>
    </div>
    <SucursalSelector
      :isOpen="modalOpen"
      :sucursals="sucursals"
      :showSelection="showSelection"
      @close="modalOpen = false"
      @success="handleModalSuccess"
    />
    <ConcessionaireUsersModal
      :isOpen="showUsersModal"
      :concessionaire="selectedConcessionaire"
      @close="showUsersModal = false"
    />

    <ConcessionaireDetailsModal
      v-if="showDetailsModal"
      :concessionaire="selectedConcessionaireDetails"
      @close="showDetailsModal = false"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  getConcessionaireTypeLabel,
  getConcessionaireTypeValue,
} from "@/constants/concessionaireTypes.js";
import { changeCentralHouseStatus } from "@/services/api/concessionaireService";
import Swal from "sweetalert2";
import SucursalSelector from "./components/SucursalSelector.vue";
import { useConcessionaire } from "@/composables/concessionaire/useConcessionaire";
import { useConcessionaireDisabling } from "@/composables/concessionaire/useConcessionaireDisabling";
import { getSucursals } from "@/services/api/concessionaireService.js";
import { useVehicleTypes } from "@/composables/useVehicleTypes";
import ConcessionaireUsersModal from "./components/ConcessionaireUsersModal.vue";

import ConcessionaireDetailsModal from "./components/ConcessionaireDetailsModal.vue";
import { useConcessionaireFilters } from "@/composables/concessionaire/useConcessionaireFilters";
import ConcessionaireHeader from "./components/ConcessionaireHeader.vue";
import ConcessionaireDropdownFilters from "./components/filters/ConcessionaireDropdownFilters.vue";

export default {
  name: "ConcesionarioView",
  components: {
    SucursalSelector,
    ConcessionaireUsersModal,
    ConcessionaireDetailsModal,
    ConcessionaireHeader,
    ConcessionaireDropdownFilters,
  },
  setup() {
    
    const showDetailsModal = ref(false);
    const selectedConcessionaireDetails = ref(null);

    const showUsersModal = ref(false);
    const selectedConcessionaire = ref(null);

    const selectedSucursal = ref(null);
    const modalOpen = ref(false);
    const showSelection = ref(true);

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const { getVehicleTypeName } = useVehicleTypes();

    const perPage = ref(15);
    const searchTerm = ref("");
    const dropdownVisible = ref(null);

    const {
      concessionaires,
      loading,
      error,
      currentPage,
      lastPage,
      hasMorePages,
      totalRegisters,
      totalWithoutAdministrators,
      totalWithoutusers,
      fetchConcessionaires,
    } = useConcessionaire();

    const {
      selectedProvince,
      selectedLocality,
      selectedRegional,
      selectedBrand,
      selectedSector,
      provincesOptions,
      localitiesOptions,
      regionalsOptions,
      brandsOptions,
      sectorsOptions,
      resetFilters,
      activeFilters,
      loadFilterOptions,
      loadingProvinces,
      loadingLocalities,
      loadingRegionals,
      loadingBrands,
      withoutUsers,
      withoutAdministrators,
    } = useConcessionaireFilters();

    const getBrandNames = (brands) => {
      if (!brands || brands.length === 0) return "N/A";
      return brands.map((brand) => brand.brand_name).join(", ");
    };

    const { sucursals, concessionaireId, disableConcessionaire } =
      useConcessionaireDisabling();

    const disableConcessionaireHandler = async (concessionaire) => {
      showSelection.value = true;
      await disableConcessionaire(
        concessionaire,
        loadConcessionaires,
        openModal
      );
    };

    const viewUsers = (concessionaire) => {
      if (!concessionaire) {
        console.error("El concesionario es inválido");
        return;
      }

      selectedConcessionaire.value = {
        business_name: concessionaire.business_name || "Sin nombre",
        administrators: Array.isArray(concessionaire.administrators)
          ? [...concessionaire.administrators]
          : [],
        users: Array.isArray(concessionaire.users)
          ? [...concessionaire.users]
          : [],
      };

      showUsersModal.value = true;
      closeDropdown();
    };

    const viewMoreInfo = (concessionaire) => {
      selectedConcessionaireDetails.value = concessionaire;
      showDetailsModal.value = true;
      closeDropdown();
    };

    const viewBranch = async (concessionaire) => {
      try {
        const response = await getSucursals(concessionaire.id);
        sucursals.value = response.data || [];

        if (sucursals.value.length === 0) {
          Swal.fire({
            icon: "info",
            title: "Sin Sucursales",
            text: `El concesionario ${concessionaire.business_name} no tiene sucursales registradas.`,
          });
          return;
        }

        modalOpen.value = true;
        showSelection.value = false;
      } catch (error) {
        console.error("Error al obtener las sucursales:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al intentar obtener las sucursales del concesionario.",
        });
      }
    };

    const currentConcessionaireType = computed(() => {
      const subMenu = route.params.subMenu;
      const typeValue = getConcessionaireTypeValue(subMenu);
      return getConcessionaireTypeLabel(typeValue);
    });

    const currentConcessionaireTypeValue = computed(() => {
      const subMenu = route.params.subMenu;
      return getConcessionaireTypeValue(subMenu);
    });

    const loadConcessionaires = async (page = 1) => {
      await fetchConcessionaires({
        subMenuLabel: route.params.subMenu,
        page,
        perPage: perPage.value,
        search: searchTerm.value,
        withoutUsers: withoutUsers.value ? 1 : 0,
        withoutAdministrators: withoutAdministrators.value ? 1 : 0,
        ...activeFilters.value,
      });
    };

    const toggleDropdown = (concessionaireId) => {
      dropdownVisible.value =
        dropdownVisible.value === concessionaireId ? null : concessionaireId;
    };

    const closeDropdown = () => {
      dropdownVisible.value = null;
    };

    const modalNew = () => {
      router.push({ name: "ConcessionaireForm" });
    };

    const editConcessionaire = (concessionaire) => {
      store.dispatch("updateHabitualistaData", concessionaire);
      router.push({
        name: "ConcessionaireFormStepTwo",
        query: {
          isEditing: "true",
          type: currentConcessionaireTypeValue.value,
        },
      });
      closeDropdown();
    };

    const addBranch = async (concessionaire) => {
      store.dispatch("updateHabitualistaData", concessionaire);
      router.push({
        name: "ConcessionaireFormStepTwo",
        query: {
          isEditing: "true",
          isAddBranch: "true",
        },
      });
      closeDropdown();
    };

    const openModal = () => {
      modalOpen.value = true;
    };

    const handleModalSuccess = ({ id, status }) => {
      if (!id) {
        return;
      }
      selectedSucursal.value = { id, status };
      modalOpen.value = false;
      processSelectedSucursal();
    };

    const processSelectedSucursal = async () => {
      if (!selectedSucursal.value) return;

      const { id, status } = selectedSucursal.value;

      try {
        const response = await changeCentralHouseStatus(
          concessionaireId.value,
          {
            new_central_house_id: id,
            status,
          }
        );

        showSuccessNotification(
          response.message || "Cambio realizado con éxito"
        );
        loadConcessionaires();
      } catch (error) {
        console.error("Error al procesar la sucursal seleccionada:", error);

        showNotification(
          "error",
          "Error",
          "No se pudo cambiar el estado de la casa central. Por favor, intenta de nuevo."
        );
      }
    };

    const showSuccessNotification = (message) => {
      showNotification("success", "¡Éxito!", message);
    };

    const showErrorNotification = (message) => {
      showNotification("error", "¡Error!", message);
    };

    const showNotification = (type, title, message) => {
      Swal.fire({
        icon: type,
        title,
        text: message,
        confirmButtonText: "Entendido",
        confirmButtonColor: type === "success" ? "#007BFF" : "#d33",
      });
    };

    onMounted(() => {
      document.addEventListener("click", closeDropdown);
    });

    onMounted(() => {
      loadFilterOptions();
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", closeDropdown);
    });

    watch(
      [
        selectedProvince,
        selectedLocality,
        selectedRegional,
        selectedSector,
        selectedBrand,
        searchTerm,
        () => currentConcessionaireTypeValue.value,
        withoutUsers,
        withoutAdministrators,
      ],
      (newValues, oldValues) => {
        const isConcessionaireTypeChanged = newValues[6] !== oldValues[6];

        if (isConcessionaireTypeChanged) {
          resetFilters();
          fetchConcessionaires({
            subMenuLabel: route.params.subMenu,
            page: 1,
            perPage: perPage.value,
            search: searchTerm.value,
            ...activeFilters.value,
          });
          return;
        }

        fetchConcessionaires({
          subMenuLabel: route.params.subMenu,
          province: newValues[0],
          locality: newValues[1],
          regional: newValues[2],
          sector: newValues[3],
          brand: newValues[4],
          search: newValues[5],
          withoutUsers: newValues[7] ? 1 : 0,
          withoutAdministrators: newValues[8] ? 1 : 0,
        });
      },
      { immediate: true }
    );

    return {
      withoutUsers,
      withoutAdministrators,
      selectedSector,
      sectorsOptions,
      selectedProvince,
      selectedLocality,
      selectedRegional,
      selectedBrand,
      provincesOptions,
      localitiesOptions,
      regionalsOptions,
      brandsOptions,
      resetFilters,

      totalRegisters,
      totalWithoutAdministrators,
      totalWithoutusers,

      showDetailsModal,
      selectedConcessionaireDetails,
      viewMoreInfo,
      showUsersModal,
      selectedConcessionaire,
      viewUsers,
      getVehicleTypeName,
      getBrandNames,
      showSelection,
      viewBranch,
      disableConcessionaireHandler,
      loadConcessionaires,
      showNotification,
      showErrorNotification,
      showSuccessNotification,
      selectedSucursal,
      handleModalSuccess,
      modalOpen,
      sucursals,
      currentConcessionaireTypeValue,
      currentConcessionaireType,
      concessionaires,
      loading,
      error,
      lastPage,
      currentPage,
      perPage,
      hasMorePages,
      searchTerm,
      fetchConcessionaires,
      modalNew,
      editConcessionaire,
      addBranch,
      disableConcessionaire,
      dropdownVisible,
      toggleDropdown,
      getConcessionaireTypeLabel,

      loadingProvinces,
      loadingLocalities,
      loadingRegionals,
      loadingBrands,
    };
  },
};
</script>

<style scoped>
.status-enabled {
  color: green;
  font-weight: bold;
}

.status-disabled {
  color: red;
  font-weight: bold;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-size: 1.5rem;
}

.add-button {
  font-size: 0.875rem;
  padding: 8px 10px;
  transition: transform 0.1s ease, background-color 0.3s;
}

.add-button:active {
  transform: scale(0.95);
  background-color: #003f7f;
}

.table-responsive {
  position: relative;
  overflow: visible;
}

.table-formal {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
  color: #333;
}

.dropdown .dropdown-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050;
  min-width: 150px;
  padding: 0.5rem 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.dropdown .dropdown-menu.show {
  display: block;
}

@media (max-width: 768px) {
  .header-title {
    font-size: 1.25rem;
  }

  .table-formal th,
  .table-formal td {
    padding: 6px;
    font-size: 0.75rem;
  }
}

.badge-user-counter {
  transition: transform 0.2s ease;
}

.badge-user-counter:hover {
  transform: translateY(-2px);
}
</style>
