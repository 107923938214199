import axios from '@/plugins/axios';

export default {
  /**
   * Obtiene los participantes de una conferencia
   * @param {number} conferenceId - ID de la conferencia
   * @returns {Promise} - Promise con la lista de participantes
   */
  getConferenceParticipants(conferenceId) {
    return axios.get(`/conferences/${conferenceId}/participants`);
  },


  exportConferenceParticipants(conferenceId) {
    return axios.get(`/conferences/${conferenceId}/export-conference-participants`, {
      responseType: 'blob',
      headers: {
        'Accept': 'text/csv'
      }
    });
  },
};