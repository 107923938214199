<template>
  <div>
    <div :class="{ 'user-form-container': isEditing }">
      <div v-if="isEditing || isAddBranch" class="header">
        <button
          @click="goBack('AdministradorSubMenu', { subMenu: 'usuarios' })"
          class="back-button"
        >
          <i class="fas fa-arrow-left"></i>
        </button>
        <h1>
          {{ isEditing ? "Editar Usuario" : "Nuevo Usuario" }}
        </h1>
      </div>

      <div class="form-row">
        <div class="form-group col d-flex align-items-end">
          <div class="flex-grow-1">
            <label for="cuit">
              CUIT Concesionaria <span class="required">*</span>
            </label>
            <input
              v-if="isEditing"
              type="text"
              id="cuit"
              class="form-control"
              v-model="currentCuit"
              placeholder="Ejemplo: 30-12345678-9"
            />
            <input
              v-else
              type="text"
              id="cuit"
              class="form-control"
              :value="concessionaireData.cuit"
              placeholder="Ejemplo: 30-12345678-9"
              disabled
            />
          </div>
          <div v-if="isEditing" class="ml-2">
            <button
              class="btn btn-primary"
              :disabled="isLoading"
              @click.prevent="handleCuitChange"
              title="Buscar CUIT"
            >
              <template v-if="isLoading">
                <span class="spinner"></span>
                Cargando...
              </template>
              <template v-else>
                <i class="fas fa-search"></i>
              </template>
            </button>
          </div>
        </div>

        <div class="form-group col">
          <label for="business_name">
            Razón Social <span class="required">*</span>
          </label>
          <input
            type="text"
            id="business_name"
            class="form-control"
            :value="concessionaireData.business_name || ''"
            placeholder="Ejemplo: Concesionaria S.A."
            disabled
          />
        </div>

        <div class="form-group col">
          <label for="sucursal">
            Sucursales <span class="required">*</span>
          </label>
          <template v-if="isLoading">
            <div class="spinner-select">
              <i class="spinner"></i> Cargando sucursales...
            </div>
          </template>
          <template v-else-if="mergedSucursals.length > 0">
            <select
              id="sucursal"
              class="form-control"
              v-model="localFormData.concessionaire_id"
              @change="clearError('concessionaire_id')"
            >
              <option value="" disabled>Seleccione una sucursal</option>
              <option
                v-for="sucursal in mergedSucursals"
                :key="sucursal.id"
                :value="sucursal.id"
              >
                {{ sucursal.cuit }} - {{ sucursal.business_name }}
              </option>
            </select>
            <span v-if="errors.concessionaire_id" class="text-danger">
              {{ errors.concessionaire_id }}
            </span>
          </template>
          <template v-else>
            <p class="text-danger">Sin sucursales disponibles</p>
          </template>
        </div>
      </div>

      <div class="separator-line"></div>

      <div class="form-row">
        <div class="form-group col">
          <label for="name"> Nombre <span class="required">*</span> </label>
          <input
            type="text"
            id="name"
            class="form-control"
            v-model="localFormData.name"
            @input="clearError('name')"
          />
          <span v-if="errors.name" class="text-danger">
            {{ errors.name }}
          </span>
        </div>

        <div class="form-group col">
          <label for="lastname">
            Apellido <span class="required">*</span>
          </label>
          <input
            type="text"
            id="lastname"
            class="form-control"
            v-model="localFormData.lastname"
            @input="clearError('lastname')"
          />
          <span v-if="errors.lastname" class="text-danger">
            {{ errors.lastname }}
          </span>
        </div>

        <div class="form-group col">
          <label for="dni"> DNI <span class="required">*</span> </label>
          <input
            type="number"
            id="dni"
            class="form-control"
            v-model="localFormData.dni"
            @input="clearError('dni')"
          />
          <span v-if="errors.dni" class="text-danger">{{ errors.dni }}</span>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col">
          <label class="switch-label">
            <input
              type="checkbox"
              class="switch"
              v-model="localFormData.concessionaire_admin"
            />
            <span class="slider"></span>
            ¿Es Administrador del Concesionario? <span class="required">*</span>
          </label>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col">
          <label for="email"> Email <span class="required">*</span> </label>
          <input
            type="email"
            id="email"
            class="form-control"
            v-model="localFormData.email"
            @input="clearError('email')"
          />
          <span v-if="errors.email" class="text-danger">
            {{ errors.email }}
          </span>
        </div>

       

        <div class="form-group col">
          <label for="business_phone"> Teléfono Fijo
             <span class="required">*</span>
          </label>
          <input
            type="text"
            id="business_phone"
            class="form-control"
            v-model="localFormData.business_phone"
            @input="clearError('business_phone')"
          />
          <span v-if="errors.business_phone" class="text-danger">
            {{ errors.business_phone }}
          </span>
        </div>
         <div class="form-group col">
          <label for="personal_phone">Teléfono Celular  </label>
          <input
            type="text"
            id="personal_phone"
            class="form-control"
            v-model="localFormData.personal_phone"
            @input="clearError('personal_phone')"
          />
          <span v-if="errors.personal_phone" class="text-danger">
            {{ errors.personal_phone }}
          </span>
        </div>
      </div>

      <div class="form-group mt-4">
        <label class="form-label text-primary font-weight-bold">
          Seleccione su área <span class="required">*</span>
        </label>
        <multiselect
          v-model="selectedAreas"
          :options="areas"
          :multiple="true"
          placeholder="Seleccione una o más áreas"
          label="name"
          track-by="id"
          class="multiselect-custom"
        />
      </div>

      <div class="form-group mt-4">
        <label class="form-label text-primary font-weight-bold">
          Seleccione su cargo <span class="required">*</span>
        </label>
        <multiselect
          v-model="localFormData.positions"
          :options="filteredPositions"
          :multiple="true"
          placeholder="Seleccione uno o más cargos"
          label="name"
          track-by="id"
          class="multiselect-custom"
          @update:modelValue="clearError('positions')"
        >
          <template #option="{ option }">
            <div>
              <strong>{{ option.area?.name || "Sin área" }}:</strong>
              {{ option.name }}
            </div>
          </template>
        </multiselect>
        <span v-if="errors.positions" class="text-danger">
          {{ errors.positions }}
        </span>
      </div>
      <div class="row justify-content-end">
        <div class="col-auto button-container">
          <button
            class="btn-create-user"
            @click.prevent="submitForm"
            :disabled="isSubmitting"
          >
            <template v-if="isSubmitting">
              <span class="spinner"></span> Cargando...
            </template>
            <template v-else>
              {{ isEditing ? "ACTUALIZAR USUARIO" : "CREAR USUARIO" }}
            </template>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from "vue";
import Multiselect from "vue-multiselect";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { fetchPositions } from "@/services/api/position";
import { fetchAreas } from "@/services/api/area";
import { createUser, updateUser } from "@/services/api/UserService.js";
import useValidations from "@/composables/useValidations";
import { useGoBack } from "@/composables/concessionaire/useGoBack";
import { getSucursals } from "@/services/api/concessionaireService";
import { checkConcessionaire } from "@/services/api/concessionaireService.js";
import Swal from "sweetalert2";

export default {
  name: "UserFormStepTwo",
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  components: { Multiselect },
  setup(props) {
    const mergedSucursals = ref([]);
    const isLoading = ref(false);
    const isSubmitting = ref(false);

    const store = useStore();
    const router = useRouter();

    const concessionaireData = computed(
      () => store.state.concessionaireData || {}
    );

    const userData = computed(() => store.state.userData || {});

    const { goBack } = useGoBack();

    const currentCuit = ref("N/A");

    watch(
      concessionaireData,
      (newData) => {
        if (newData) {
          currentCuit.value = newData.cuit;
          const sucursals = newData.sucursals || [];
          mergedSucursals.value = [newData, ...sucursals];
        } else {
          mergedSucursals.value = [];
        }
      },
      { immediate: true }
    );

    const resetFormData = () => {
      localFormData.name = "";
      localFormData.lastname = "";
      localFormData.dni = "";
      localFormData.email = "";
      localFormData.personal_phone = "";
      localFormData.business_phone = "";
      localFormData.concessionaire_id = null;
      localFormData.positions = [];
    };

    const initializeFormData = () => {
      if (props.isEditing && userData.value) {
        localFormData.name = userData.value.name || "";
        localFormData.lastname = userData.value.lastname || "";
        localFormData.dni = userData.value.dni || "";
        localFormData.email = userData.value.email || "";
        localFormData.personal_phone = userData.value.personal_phone || "";
        localFormData.business_phone = userData.value.business_phone || "";
        localFormData.positions = userData.value.position || [];
        localFormData.concessionaire_admin = userData.value.roles.some(
          (role) => role.name === "concessionaire-admin"
        );

        const concessionaire = userData.value.concessionaires?.[0] || null;
        if (concessionaire) {
          const sucursals = concessionaire.sucursals || [];
          localFormData.concessionaire_id = concessionaire.id;

          currentCuit.value = concessionaire.cuit || "N/A";

          concessionaireData.value = {
            ...concessionaire,
            sucursals,
          };

          mergedSucursals.value = [concessionaire, ...sucursals];
        } else {
          localFormData.concessionaire_id = null;
          concessionaireData.value = null;
          mergedSucursals.value = [];
        }

        selectedAreas.value = userData.value.position
          .map((pos) => pos.area)
          .filter(
            (area, index, self) =>
              area && self.findIndex((a) => a.id === area.id) === index
          );
      }
    };

    const localFormData = reactive({
      cuit: "",
      name: "",
      lastname: "",
      dni: "",
      email: "",
      personal_phone: "",
      business_phone: "",
      concessionaire_id: null,
      concessionaire_admin: false,
      positions: [],
    });

    const positions = ref([]);
    const areas = ref([]);
    const selectedAreas = ref([]);
    const filteredPositions = ref([]);
    const { errors, validateFields, clearError } = useValidations();

    const fetchAvailableAreas = async () => {
      const response = await fetchAreas();
      areas.value = response.data;
    };

    const fetchAvailablePositions = async () => {
      try {
        const response = await fetchPositions();
        positions.value = response || [];
        filteredPositions.value = [];
        filterPositionsByAreas();
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    const submitForm = async () => {
      const isValid = await validateFields(localFormData);
      if (!isValid) {
        console.warn(
          "La validación del formulario falló. Corrección requerida."
        );
        return;
      }
      if (
        concessionaireData.value &&
        Array.isArray(concessionaireData.value.sucursals) &&
        concessionaireData.value.sucursals.length === 0
      ) {
        localFormData.concessionaire_id = concessionaireData.value.id;
      }
      isSubmitting.value = true;
      try {
        if (props.isEditing) {
          await handleUpdateUser();
        } else {
          await handleCreateUser();
        }
        handleSuccess(
          `Usuario ${props.isEditing ? "actualizado" : "creado"} exitosamente`
        );
      } catch (error) {
        handleError(
          error,
          `Error al ${props.isEditing ? "actualizar" : "crear"} usuario`
        );
      } finally {
        isSubmitting.value = false;
      }
    };

    const handleCreateUser = async () => {
      await createUser({
        ...localFormData,
        positions: localFormData.positions.map((pos) => pos.id),
      });
    };

    const handleUpdateUser = async () => {
      await updateUser(userData.value.id, {
        ...localFormData,
        positions: localFormData.positions.map((pos) => pos.id),
      });
    };

    const handleSuccess = (message) => {
      Swal.fire({
        icon: "success",
        title: message,
      }).then(() => {
        router.push({
          name: "AdministradorSubMenu",
          params: { subMenu: "usuarios" },
        });
      });
    };

    const handleError = (error, title) => {
      console.error(title, error);
      const errorMessage = error.response?.data?.message || "Error inesperado";
      Swal.fire({
        icon: "error",
        title,
        text: errorMessage,
      });
    };

    watch(selectedAreas, (newAreas) => {
      filterPositionsByAreas();

      const validAreaIds = newAreas.map((area) => area.id);
      localFormData.positions = localFormData.positions.filter((position) =>
        validAreaIds.includes(position.area_id)
      );
    });

    const filterPositionsByAreas = () => {
      if (selectedAreas.value.length > 0) {
        const selectedAreaIds = selectedAreas.value.map((area) => area.id);
        filteredPositions.value = positions.value.filter((position) =>
          selectedAreaIds.includes(position.area_id)
        );
      } else {
        filteredPositions.value = [];
      }
    };

    const handleCuitChange = async () => {
      const cuitValue = currentCuit.value;

      if (!cuitValue || cuitValue === "N/A") {
        clearConcessionaireData();
        return;
      }

      isLoading.value = true;
      try {
        const result = await checkConcessionaire(cuitValue);

        if (result && typeof result === "object") {
          store.commit("setConcessionaireData", result);
          concessionaireData.value = result;

          if (result.sucursals && Array.isArray(result.sucursals)) {
            localFormData.concessionaire_id = result.sucursals[0]?.id || null;
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "Sin resultados",
            text: "No se encontraron datos para el CUIT proporcionado.",
            confirmButtonText: "Entendido",
          });
          clearConcessionaireData();
        }
      } catch (error) {
        console.error("Error al verificar el CUIT:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un problema al verificar el CUIT. Por favor, intenta nuevamente.",
          confirmButtonText: "Cerrar",
        });
        clearConcessionaireData();
      } finally {
        isLoading.value = false;
      }
    };

    const clearConcessionaireData = () => {
      store.commit("setConcessionaireData", {
        sucursals: [],
        business_name: "",
        cuit: "",
      });

      localFormData.concessionaire_id = null;

      if (props.isEditing) {
        store.commit("updateUserData", {
          ...userData.value,
          concessionaires: [],
        });
      }

      concessionaireData.value = { sucursals: [] };
    };

    onMounted(async () => {
      if (props.isEditing && userData.value?.concessionaires?.[0]?.id) {
        try {
          await getSucursals(
            userData.value.concessionaires[0].id
          );
        } catch (error) {
          console.error("Error al obtener sucursales:", error);
        }
      }
    });

    onMounted(() => {
      resetFormData();
      if (props.isEditing) {
        initializeFormData();
      }
      fetchAvailableAreas();
      fetchAvailablePositions();
    });

    return {
      isSubmitting,
      isLoading,
      currentCuit,
      handleCuitChange,
      mergedSucursals,
      localFormData,
      userData,
      concessionaireData,
      positions,
      areas,
      selectedAreas,
      filteredPositions,
      submitForm,
      errors,
      filterPositionsByAreas,
      resetFormData,
      clearError,
      goBack,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.spinner-select {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  font-size: 0.875rem;
  color: #555;
}

.spinner-select i {
  margin-right: 5px;
}

.custom-width {
  width: 32%;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #333;
}

.spinner {
  margin-right: 8px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0056b3;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: baseline;
}

.form-row .col {
  flex: 1;
}

.separator-line {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.select-wrapper {
  position: relative;
}

.select-style {
  appearance: none;
  background-color: #f0f0f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-right: 25px;
}

.select-wrapper::after {
  content: "▾";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 0.875rem;
  color: #555;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.continue-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 0.875rem;
}

.small-button {
  font-size: 0.875rem;
  padding: 8px 16px;
}

.user-form-container {
  margin: 0 auto;
  max-width: 1320px;
  padding: 24px;
  border-radius: 8px;
}

.header {
  display: flex !important;
  align-items: center !important;
  gap: 10px;
  margin-bottom: 46px;
}

.back-button {
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
}

.back-button {
  margin-right: auto;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .user-form-container {
    padding: 16px;
    max-width: 100%;
  }

  .header {
    flex-direction: column;
    text-align: center;
  }
  .form-row {
    flex-direction: column;
    gap: 10px;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

  .continue-button {
    width: 100%;
    max-width: 200px;
  }

  .form-group,
  .select-style {
    width: 100%;
  }

  .small-button {
    padding: 10px 16px;
  }
}

.required {
  color: #d9534f;
  font-weight: bold;
  margin-left: 5px;
}

/* switch */
.switch-label {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #6c757d;
  cursor: pointer;
}

.switch {
  display: none;
}

.slider {
  position: relative;
  width: 40px;
  height: 20px;
  margin-right: 10px;
  background-color: #d3d3d3;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
}

.switch:checked + .slider {
  background-color: #00a0e1;
}

.switch:checked + .slider::before {
  transform: translateX(20px);
}

/* Button */
.btn-create-user {
  background-color: #0000ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-create-user:hover {
  background-color: #0000cc;
}

.btn-create-user:active {
  background-color: #000099;
}

.form-control {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px;
  font-size: 1rem;
  color: #495057;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-control:focus {
  background-color: #ffffff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: none;
}

.button-container {
  margin-right: 20px;
  text-align: right;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0056b3;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

.spinner-select {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  font-size: 0.875rem;
  color: #555;
}

.spinner-select i {
  margin-right: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>