
export const useExporter = () => {
  const handleExport = async (fetchData, defaultFilename) => {
    const response = await fetchData();
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    
    const filename = response.headers['content-disposition']
      ? response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
      : defaultFilename;

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

    return filename;
  };

  return { handleExport };
};