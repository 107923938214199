import { ref, computed } from 'vue';
import { fetchProvinces } from '@/services/api/provinceService';
import { fetchRegionals } from '@/services/api/regionalService';
import { fetchAllBrands } from '@/services/api/brandService';
import { fetchAllLocalities } from '@/services/api/provinceService';
import { vehicleTypes } from '@/services/api/vehicleTypes';

export function useConcessionaireFilters() {
    const selectedProvince = ref('');
    const selectedLocality = ref('');
    const selectedRegional = ref('');
    const selectedBrand = ref('');
    const selectedSector = ref('');

    const provincesOptions = ref([]);
    const localitiesOptions = ref([]);
    const regionalsOptions = ref([]);
    const brandsOptions = ref([]);
    const sectorsOptions = computed(() => vehicleTypes);

    const loadingProvinces = ref(true);
    const loadingLocalities = ref(true);
    const loadingRegionals = ref(true);
    const loadingBrands = ref(true);

    const withoutUsers = ref(false);
    const withoutAdministrators = ref(false);

    const loadFilterOptions = async () => {
        try {
            loadingProvinces.value = true;
            loadingLocalities.value = true;
            loadingRegionals.value = true;
            loadingBrands.value = true;


            // Cargar provincias
            const provincesResponse = await fetchProvinces();
            provincesOptions.value = provincesResponse.data;
            loadingProvinces.value = false;
            // Cargar todas las localidades
            const localitiesResponse = await fetchAllLocalities();
            localitiesOptions.value = localitiesResponse.data;
            loadingLocalities.value = false;

            // Cargar regionales
            const regionalsResponse = await fetchRegionals();
            regionalsOptions.value = regionalsResponse.data;
            loadingRegionals.value = false;

            // Cargar marcas iniciales
            const brandsResponse = await fetchAllBrands();
            brandsOptions.value = brandsResponse;
            loadingBrands.value = false;

        } catch (error) {
            console.error('Error loading filter options:', error);
            loadingProvinces.value = false;
            loadingLocalities.value = false;
            loadingRegionals.value = false;
            loadingBrands.value = false;
        }
    };




    const resetFilters = () => {
        selectedProvince.value = '';
        selectedLocality.value = '';
        selectedRegional.value = '';
        selectedBrand.value = '';
        selectedSector.value = '';
        withoutUsers.value = false;
        withoutAdministrators.value = false;
    };

    return {
        withoutUsers,
        withoutAdministrators,
        // Estados
        selectedProvince,
        selectedLocality,
        selectedRegional,
        selectedBrand,
        selectedSector,

        loadingProvinces,
        loadingLocalities,
        loadingRegionals,
        loadingBrands,

        // Opciones
        provincesOptions: computed(() => provincesOptions.value),
        localitiesOptions: computed(() => localitiesOptions.value),
        regionalsOptions: computed(() => regionalsOptions.value),
        brandsOptions: computed(() => brandsOptions.value),
        sectorsOptions,

        // Métodos
        resetFilters,
        loadFilterOptions,

        // Filtros
        activeFilters: computed(() => ({
            province: selectedProvince.value,
            locality: selectedLocality.value,
            regional: selectedRegional.value,
            brand: selectedBrand.value,
            sector: selectedSector.value,
            without_users: withoutUsers.value ? 1 : 0,
            without_administrators: withoutAdministrators.value ? 1 : 0,
        }))
    };
}