<template>
  <div class="container p-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="mb-0" style="font-size: 2rem">Regionales</h1>
      <div class="d-flex align-items-center">
        <button @click="openModal(null)" class="btn btn-primary">Nueva Regional</button>
      </div>
    </div>

    <div class="mb-3">
      <input
        type="text"
        v-model="searchTerm"
        class="form-control"
        placeholder="Buscar..."
      />
    </div>

    <div v-if="loading" class="text-center">Cargando...</div>
    <div v-else>
      <table class="table table-striped table-bordered" style="width: 100%">
        <thead class="thead-dark">
          <tr>
            <th>Nombre</th>
            <th>Código</th>
            <th>Provincia</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="regional in regionales" :key="regional.id">
            <td>{{ regional.name }}</td>
            <td>{{ regional.code }}</td>
            <td>{{ regional.province_name }}</td>
            <td>{{ regional.status === 1 ? 'Activo' : 'Inactivo' }}</td>
            <td class="text-center">
              <button
                @click="editRegional(regional)"
                class="btn btn-sm btn-outline-primary mr-2"
              >
                Editar
              </button>
              <button
                @click="deleteRegionalById(regional.id)"
                class="btn btn-sm btn-outline-danger"
              >
                Eliminar
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="lastPage > 1" class="d-flex justify-content-end">
        <button @click="fetchRegionales(1)" class="btn btn-sm btn-outline-secondary mr-2" :disabled="currentPage === 1">
          Primera
        </button>
        <button @click="fetchRegionales(currentPage - 1)" class="btn btn-sm btn-outline-secondary mr-2" :disabled="currentPage === 1">
          Anterior
        </button>
        <button @click="fetchRegionales(currentPage + 1)" class="btn btn-sm btn-outline-secondary mr-2" :disabled="!hasMorePages">
          Siguiente
        </button>
        <button @click="fetchRegionales(lastPage)" class="btn btn-sm btn-outline-secondary" :disabled="currentPage === lastPage">
          Última
        </button>
      </div>
    </div>

    <ModalFormRegional
      :isOpen="modalOpen"
      :regional="selectedRegional"
      :provinces="provinces"
      @close="modalOpen = false"
      @success="handleModalSuccess"
    />
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { fetchRegionals, deleteRegional } from "@/services/api/regionalService.js";
import { fetchProvinces } from "@/services/api/provinceService.js";
import ModalFormRegional from "@/components/regional/ModalFormRegional.vue";
import userConfirmation from "@/utils/userConfirmation.js";

export default {
  components: {
    ModalFormRegional,
  },
  setup() {
    const regionales = ref([]);
    const provinces = ref([]);
    const loading = ref(false);
    const searchTerm = ref("");
    const orderByMethod = ref("asc");
    const perPage = ref(10);
    const status = ref(null);
    const modalOpen = ref(false);
    const selectedRegional = ref(null);
    const currentPage = ref(1);
    const lastPage = ref(1);
    const hasMorePages = ref(false);

    const fetchRegionales = async (page = 1) => {
      loading.value = true;
      try {
        const data = await fetchRegionals({
          search: searchTerm.value,
          orderByMethod: orderByMethod.value,
          perPage: perPage.value,
          status: status.value,
          page,
        });
        regionales.value = data.data || [];
        currentPage.value = data.meta.current_page;
        lastPage.value = data.meta.last_page;
        hasMorePages.value = data.meta.current_page < data.meta.last_page;
        
      } catch (error) {
        console.error("Error fetching regionales:", error);
      } finally {
        loading.value = false;
      }
    };

    watch([searchTerm, orderByMethod, perPage, status], fetchRegionales);

    const fetchProvincias = async () => {
      try {
        const response = await fetchProvinces({
          orderByMethod: orderByMethod.value,
          status: status.value,
        });
        
        provinces.value = response.data || [];

      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };

    const openModal = (regional) => {
      selectedRegional.value = regional;
      modalOpen.value = true;
    };

    const handleModalSuccess = () => {
      modalOpen.value = false;
      fetchRegionales();
    };

    const editRegional = (regional) => {
      openModal(regional);
    };

    const deleteRegionalById = async (id) => {
      const confirmed = await userConfirmation(
        `¿Está seguro de eliminar la regional seleccionada?`
      );
      if (!confirmed) return;
      try {
        await deleteRegional(id);
        fetchRegionales();
      } catch (error) {
        console.error("Error deleting regional:", error);
      }
    };

    onMounted(() => {
      fetchProvincias();
      fetchRegionales();
    });

    return {
      provinces,
      regionales,
      loading,
      searchTerm,
      orderByMethod,
      perPage,
      status,
      fetchRegionales,
      modalOpen,
      selectedRegional,
      openModal,
      handleModalSuccess,
      editRegional,
      deleteRegionalById,
      currentPage,
      lastPage,
      hasMorePages,
    };
  },
};
</script>
