<template>
  <div
    class="modal fade"
    id="sliderModal"
    tabindex="-1"
    aria-labelledby="sliderModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg modal-custom-width">
      <div class="modal-content">
        <ModalHeader
          :view="view"
          subtitle="Section"
          modalId="sliderModal"
          @close="closeModal"
        />
        <div class="modal-body">
          <div class="d-flex justify-content-between mb-3">
            <SearchInput v-model="searchTerm" />
            <button class="btn btn-primary" @click="openNewBlock">Nuevo</button>
          </div>
          <div v-if="loading" class="text-center">Cargando...</div>
          <div v-else>
            <div class="table-responsive" style="padding-bottom: 20px">
              <table
                ref="table"
                class="table table-bordered table-striped table-responsive-stack"
              >
                <thead>
                  <tr>
                    <th class="text-center">Nombre</th>
                    <th class="text-center">Titulo</th>
                    <th class="text-center">Estado</th>
                    <th class="text-center">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="block in filteredBlocks" :key="block.id">
                    <td>{{ block.name || "Sin datos disponibles" }}</td>
                    <td>{{ block.title  || "Sin datos disponibles" }}</td>
                    <td data-label="Estado" class="text-center">
                      <div class="custom-switch">
                        <input
                          type="checkbox"
                          :id="'switch-' + block.id"
                          v-model="block.status"
                          :true-value="2"
                          :false-value="1"
                          @change="() => updateBlockStatusSwitch(block)"
                        />
                        <label :for="'switch-' + block.id"></label>
                      </div>
                    </td>
                    <td data-label="Opciones">
                      <div class="d-flex justify-content-evenly">
                        <button
                          class="btn btn-sm btn-outline-primary"
                          @click="editBlock(block)"
                        >
                          Editar
                        </button>
                        <button
                          class="btn btn-sm btn-outline-danger"
                          @click="removeSection(block.id)"
                        >
                          Eliminar
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <DataPagination
              :current-page="currentPage"
              :last-page="lastPage"
              :has-more-pages="hasMorePages"
              @fetch-views="loadBlocks"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <BlockFormModal
    v-if="showBlockForm"
    :view="view"
    :header="selectedBlock"
    @close="closeBlockForm"
    @section-created="loadBlocks"
    @success="handleSuccessAndReload"
    @error="handleError"
  />
</template>

<script>
import { reactive, ref, computed, onMounted, watch } from "vue";
import { Modal } from "bootstrap";
import ModalHeader from "@/components/shared/ModalHeader.vue";
import BlockFormModal from "./BlockFormModal.vue";
import DataPagination from "@/components/data-display/DataPagination.vue";
import SearchInput from "@/components/data-display/SearchInput.vue";
import userConfirmation from "@/utils/userConfirmation.js";
import {
  fetchBlocks,
  deleteBlock,
  updateBlockStatus,
} from "@/services/api/blockService.js";
import { usePagination } from "@/composables/usePagination";
import { useNotification } from "@/utils/notificationUtils";

const SECTION_MODAL_ID = "sliderModal";

export default {
  components: {
    ModalHeader,
    BlockFormModal,
    DataPagination,
    SearchInput,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const blocks = reactive([]);
    const loading = ref(false);
    const showBlockForm = ref(false);
    const selectedBlock = ref(null);
    const searchTerm = ref("");

    const { currentPage, lastPage, hasMorePages } = usePagination();
    const { handleSuccess, handleError } = useNotification();

    const loadBlocks = async (page = 1) => {
      loading.value = true;
      try {
        const response = await fetchBlocks(
          props.view.id,
          page,
          10,
          searchTerm.value
        );
        blocks.splice(0, blocks.length, ...response.data);
        currentPage.value = response.meta.current_page || 1;
        lastPage.value = response.meta.last_page || 1;
        hasMorePages.value = currentPage.value < lastPage.value;
      } catch (error) {
        console.error("Error fetching buttonLinks:", error);
      } finally {
        loading.value = false;
      }
    };

    watch(searchTerm, () => {
      loadBlocks(1);
    });

    const handleSuccessAndReload = (message) => {
      handleSuccess(message);
      loadBlocks();
    };

    const filteredBlocks = computed(() => {
      if (!searchTerm.value) {
        return blocks;
      }
      return blocks.filter((block) =>
        block.name.toLowerCase().includes(searchTerm.value.toLowerCase())
      );
    });

    const openNewBlock = () => {
      selectedBlock.value = null;
      showBlockForm.value = true;
    };

    const closeBlockForm = () => {
      showBlockForm.value = false;
    };

    const closeModal = () => {
      emit("close");
    };

    const editBlock = (block) => {
      selectedBlock.value = block;
      showBlockForm.value = true;
    };

    const removeSection = async (blockId) => {
      const confirmed = await userConfirmation(
        "Está seguro de eliminar el Bloque seleccionado"
      );
      if (!confirmed) return;
      try {
        await deleteBlock(blockId);
        loadBlocks(currentPage.value);
      } catch (error) {
        console.error("Error eliminando block:", error);
      }
    };

    const updateBlockStatusSwitch = async (block) => {
      try {
        await updateBlockStatus(block.id, block.status);
        loadBlocks(currentPage.value);
      } catch (error) {
        console.error("Error eliminando block:", error);
      }
    };

    onMounted(async () => {
      loadBlocks();
      const modalElement = document.getElementById(SECTION_MODAL_ID);
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    });

    return {
      blocks,
      loading,
      showBlockForm,
      selectedBlock,
      searchTerm,
      currentPage,
      lastPage,
      hasMorePages,
      filteredBlocks,
      handleSuccessAndReload,
      loadBlocks,
      removeSection,
      openNewBlock,
      closeBlockForm,
      closeModal,
      editBlock,
      handleSuccess,
      handleError,
      updateBlockStatusSwitch,
    };
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f9f9f9;
}

.modal-custom-width {
  max-width: 85%;
}

.img-thumbnail {
  object-fit: cover;
}

.custom-switch {
  display: inline-block;
  width: 43px;
  height: 28px;
  position: relative;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-switch label:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  left: 1px;
  bottom: 1px;
  transition: transform 0.3s;
}

.custom-switch input:checked + label {
  background-color: #4caf50;
}

.custom-switch input:checked + label:before {
  transform: translateX(20px);
}

.table-responsive-stack {
  width: 100%;
  table-layout: fixed;
}

.table-responsive-stack th,
.table-responsive-stack td {
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .table-responsive-stack tr {
    display: block;
    border-bottom: 3px solid #ccc;
  }

  .table-responsive-stack th {
    display: none;
  }

  .table-responsive-stack td {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    padding-left: 50%;
    box-sizing: border-box;
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
  }

  .modal-content {
    width: 100%;
  }

  .modal-custom-width {
    max-width: 100%;
  }
}

.table-responsive {
  padding-bottom: 20px;
}

tbody {
  width: 100%;
}
</style>
