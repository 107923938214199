<template>
  <div class="alert alert-info mb-0">
    <i class="fas fa-info-circle mr-2"></i>{{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'No se encontraron registros'
    }
  }
}
</script>

<style scoped>
.alert-info {
  background-color: #e7f3ff;
  border-color: #cce4ff;
  color: #004085;
}
</style>