<template>
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead class="bg-light">
        <tr>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>DNI</th>
          <th>Tel. Laboral</th>
          <th>Tel. Personal</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td>{{ user.name }}</td>
          <td>{{ user.lastname }}</td>
          <td>{{ user.dni }}</td>
          <td>{{ user.business_phone || 'N/A' }}</td>
          <td>{{ user.personal_phone || 'N/A' }}</td>
          <td>{{ user.email }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style scoped>
.table-responsive {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.table thead th {
  position: sticky;
  top: 0;
  background: #f8f9fa;
  z-index: 1;
}

.table-hover tbody tr:hover {
  background-color: #f8f9fa;
}
</style>