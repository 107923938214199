<template>
  <div
    v-if="!isLoading"
    :class="{ 'concessionaire-form-container': isEditing }"
  >
    <div v-if="isEditing || isAddBranch" class="header">
      <button
        @click="goBack('ConcessionaireSubMenu', { subMenu: 'casa-central' })"
        class="back-button"
      >
        <i class="fas fa-arrow-left"></i>
      </button>
      <h1>
        {{
          isAddBranch
            ? "Agregar Sucursal"
            : currentConcessionaireType === 1
            ? "Editar Concesionario"
            : "Editar Sucursal"
        }}
      </h1>
    </div>

    <div class="form-row">
  <!-- Columna 1: CUIT -->
  <div class="form-group col">
    <label for="cuit">CUIT del Comerciante Habitualista</label>
    <input
      type="text"
      id="cuit"
      class="form-control"
      :value="habitualistaData.cuit"
      disabled
    />
  </div>

  <div v-if="habitualistaData.central_house" class="form-group col">
    <label for="central-house-name">Razón Social</label>
    <input
      type="text"
      id="central-house-name"
      class="form-control"
      :value="habitualistaData.central_house.business_name"
      disabled
    />
  </div>

  <div class="form-group col">
    <label for="business_name">
      Nombre de la Sucursal
      <span class="required">*</span>
    </label>
    <input
      type="text"
      id="business_name"
      class="form-control"
      placeholder="Ingresar Nombre de la Sucursal"
      v-model="business_name"
    />
    <span v-if="formErrors.business_name" class="text-danger">
      {{ formErrors.business_name }}
    </span>
  </div>
</div>

    <div class="separator-line"></div>


    <div class="form-row">
      <div class="form-group col">
        <label for="province_id">
          Provincia
          <span class="required">*</span>
        </label>
        <div class="select-wrapper">
          <select
            id="province_id"
            class="form-control select-style"
            v-model="province_id"
          >
            <option value="">Seleccionar Provincia</option>
            <option
              v-for="province in provinces.data"
              :key="province.id"
              :value="province.id"
            >
              {{ province.name }}
            </option>
          </select>
        </div>
        <span v-if="formErrors.province_id" class="text-danger">
          {{ formErrors.province_id }}
        </span>
      </div>

      <div class="form-group col">
        <label for="locality">
          Localidad
          <span class="required">*</span>
        </label>
        <div class="select-wrapper">
          <select
            id="locality"
            class="form-control select-style"
            v-model="locality_id"
            :disabled="isLoadingLocalities"
          >
            <option value="">Seleccionar Localidad</option>
            <template v-if="!isLoadingLocalities">
              <option
                v-for="localityOption in localities.data"
                :key="localityOption.id"
                :value="localityOption.id"
              >
                {{ localityOption.name }}
              </option>
            </template>
          </select>
          <div v-if="isLoadingLocalities" class="spinner-select">
            <i class="fas fa-spinner fa-spin"></i> Cargando...
          </div>
          <span v-if="formErrors.locality_id" class="text-danger">
            {{ formErrors.locality_id }}
          </span>
        </div>
      </div>

      <div class="form-group col">
        <label for="regional_id">
          Regional
          <span class="required">*</span>
        </label>
        <div class="select-wrapper">
          <select
            id="regional_id"
            class="form-control select-style"
            v-model="regional_id"
            :disabled="isLoadingRegionals"
          >
            <option value="">Seleccionar Regional</option>
            <template v-if="!isLoadingRegionals">
              <option
                v-for="regional in regionals.data"
                :key="regional.id"
                :value="regional.id"
              >
                {{ regional.name }}
              </option>
            </template>
          </select>
          <div v-if="isLoadingRegionals" class="spinner-select">
            <i class="fas fa-spinner fa-spin"></i> Cargando...
          </div>
          <span v-if="formErrors.regional_id" class="text-danger">
            {{ formErrors.regional_id }}
          </span>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="business_name">
          Dirección
          <span class="required">*</span>
        </label>
        <input
          type="text"
          id="street_name"
          class="form-control"
          placeholder="Ingresar Dirección"
          v-model="street_name"
        />
        <span v-if="formErrors.street_name" class="text-danger">
          {{ formErrors.street_name }}
        </span>
      </div>

      <div class="form-group col">
        <label for="business_name">
          Número
          <span class="required">*</span>
        </label>
        <input
          type="text"
          id="street_number"
          class="form-control"
          placeholder="Ingresar Número"
          v-model="street_number"
          @input="handleStreetNumberInput"
        />
        <span v-if="formErrors.street_number" class="text-danger">
          {{ formErrors.street_number }}
        </span>
      </div>

      <div class="form-group col">
        <label for="business_name">
          Código Postal
          <span class="required">*</span>
        </label>
        <input
          type="text"
          id="postal_code"
          class="form-control"
          placeholder="Ingresar Código Postal"
          v-model="postal_code"
          @input="
            (event) =>
              validateNumericField(
                'postal_code',
                event.target.value,
                'El código postal'
              )
          "
        />
        <span v-if="formErrors.postal_code" class="text-danger">
          {{ formErrors.postal_code }}
        </span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="phone">
          Teléfono
          <span class="required">*</span>
        </label>
        <input
          type="text"
          id="phone"
          class="form-control"
          placeholder="Ingresar Teléfono"
          v-model="phone"
          @input="
            (event) =>
              validateNumericField('phone', event.target.value, 'El teléfono')
          "
        />
        <span v-if="formErrors.phone" class="text-danger">
          {{ formErrors.phone }}
        </span>
      </div>

      <div class="form-group col">
        <label for="email">
          Email
          <span class="required">*</span>
        </label>
        <input
          type="email"
          id="email"
          class="form-control"
          placeholder="Ingresar Correo Electrónico"
          v-model="email"
          @input="handleEmailInput"
        />
        <span v-if="formErrors.email" class="text-danger">
          {{ formErrors.email }}
        </span>
      </div>
      <div class="form-group col">
        <label for="business_name">Website</label>
        <input
          type="text"
          id="website"
          class="form-control"
          placeholder="Ingresar website"
          v-model="website"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="vehicle_type">
          Rubro
          <span class="required">*</span>
        </label>
        <div class="select-wrapper">
          <select
            id="vehicle_type"
            class="form-control select-style"
            v-model="vehicle_type"
            @change="handleVehicleTypeChange"
          >
            <option value="">Seleccionar Rubro</option>
            <option
              v-for="type in vehicleTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
        <span v-if="formErrors.vehicle_type" class="text-danger">
          {{ formErrors.vehicle_type }}
        </span>
      </div>

      <div class="form-group col">
        <label for="brands">
          Marca
          <span class="required">*</span>
        </label>
        <multiselect
          v-model="brands"
          :options="brandsOptions"
          :multiple="true"
          placeholder="Seleccione una o más marcas"
          label="name"
          track-by="id"
          class="multiselect-custom"
        >
          <template #option="{ option }">
            <div>
              {{ option.name }}
            </div>
          </template>
        </multiselect>
        <span v-if="formErrors.brands" class="text-danger">
          {{ formErrors.brands }}
        </span>
      </div>
    </div>

    <div :class="buttonContainerClass">
      <button
        @click="submitForm"
        class="continue-button small-button"
        :disabled="isSubmitting"
      >
        <span v-if="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
          {{
            isAddBranch
              ? "Agregando Sucursal..."
              : isEditing
              ? currentConcessionaireType === 1
                ? "Actualizando Concesionario..."
                : "Actualizando Sucursal..."
              : "Creando..."
          }}
        </span>
        <span v-else>
          {{
            isAddBranch
              ? "AGREGAR SUCURSAL"
              : isEditing
              ? currentConcessionaireType === 1
                ? "ACTUALIZAR CONCESIONARIO"
                : "ACTUALIZAR SUCURSAL"
              : "CREAR CONCESIONARIO"
          }}
        </span>
      </button>
    </div>
  </div>
  <div v-else class="loading-container">
    <div class="spinner"></div>
    <p>Cargando datos del formulario...</p>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import {
  createConcessionaire,
  updateConcessionaire,
  addSucursal,
} from "@/services/api/concessionaireService.js";
import {
  fetchProvinces,
  fetchAllLocalities,
  fetchProvinceRegionals,
  fetchProvinceLocalities,
} from "@/services/api/provinceService";

import { fetchRegionals } from "@/services/api/regionalService";
import { fetchBrandsByVehiculeType } from "@/services/api/brandService";
import { vehicleTypes } from "@/services/api/vehicleTypes";
import { useFormValidation } from "@/composables/concessionaire/useFormValidation.js";
import { useGoBack } from "@/composables/concessionaire/useGoBack";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "ConcessionaireFormStepTwo",
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    isAddBranch: {
      type: Boolean,
      default: false,
    },
    currentConcessionaireType: Number,
  },
  components: {
    Multiselect,
  },
  setup(props) {
    const store = useStore();
    const habitualistaData = computed(() => store.state.habitualistaData || {});

    const { goBack } = useGoBack();

    const isSubmitting = ref(false);
    const isLoading = ref(true);
    const isLoadingLocalities = ref(false);
    const isLoadingRegionals = ref(false);

    const business_name = ref(habitualistaData.value.business_name || "");
    const province_id = ref(habitualistaData.value.province_id || "");
    const locality_id = ref(habitualistaData.value.locality_id || "");
    const street_name = ref(habitualistaData.value.street_name || "");
    const street_number = ref(habitualistaData.value.street_number || "");
    const postal_code = ref(habitualistaData.value.postal_code || "");
    const phone = ref(habitualistaData.value.phone || "");
    const email = ref(habitualistaData.value.email || "");
    const brands = ref([]);
    const regional_id = ref(habitualistaData.value.regional_id || "");
    const website = ref(habitualistaData.value.website || "");

    const vehicle_type = ref(habitualistaData.value.brand_vehicule_type || "");

    const provinces = ref([]);
    const regionals = ref([]);
    const brandsOptions = ref([]);
    const localities = ref([]);

    const {
      formErrors,
      validateEmail,
      validateNumericField,
      validateConcessionaireForm,
    } = useFormValidation();

    const buttonContainerClass = computed(() => ({
      "button-container d-flex": true,
      "justify-content-end": props.isEditing,
    }));

    const vehicleTypesList = vehicleTypes;

    const handleStreetNumberInput = (event) => {
      street_number.value = validateNumericField(
        "street_number",
        event.target.value,
        "El número"
      );
    };

    const handleEmailInput = () => {
      validateEmail(email.value);
    };

    onMounted(async () => {
      try {
        provinces.value = await fetchProvinces({
          perPage: 40,
          orderByMethod: "asc",
        });

        if (habitualistaData.value.sector) {
          vehicle_type.value = habitualistaData.value.sector;
          brandsOptions.value = await fetchBrandsByVehiculeType(
            vehicle_type.value
          );
        }

        processBrands();

        handleRegionalsAndLocalities();
      } catch (error) {
        console.error("Error al cargar datos iniciales:", error);
        isLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    });

    const processBrands = async () => {
      if (habitualistaData.value.brands) {
        const matchedBrands = await Promise.all(
          habitualistaData.value.brands.map(async (brand) => {
            const matchedBrand = brandsOptions.value.find(
              (option) => option.id === brand.brand_id
            );
            return matchedBrand || null;
          })
        );

        brands.value = matchedBrands.filter(Boolean);
      }
    };

    const handleRegionalsAndLocalities = async () => {
      if (province_id.value) {
        const [regionalsData, localitiesData] = await Promise.all([
          fetchProvinceRegionals(province_id.value),
          fetchProvinceLocalities(province_id.value),
        ]);

        regionals.value = regionalsData || [];
        localities.value = localitiesData || [];
      } else {
        const [regionalsFetched, localitiesFetched] = await Promise.all([
          fetchRegionals(),
          fetchAllLocalities(),
        ]);

        regionals.value = regionalsFetched || [];
        localities.value = localitiesFetched || [];
      }
    };

    watch(province_id, async (newProvinceId) => {
      locality_id.value = "";
      regional_id.value = "";
      if (newProvinceId) {
        isLoadingLocalities.value = true;
        isLoadingRegionals.value = true;
        try {
          const [regionalsData, localitiesData] = await Promise.all([
            fetchProvinceRegionals(newProvinceId),
            fetchProvinceLocalities(newProvinceId),
          ]);
          regionals.value = regionalsData || [];
          localities.value = localitiesData || [];
        } catch (error) {
          console.error(
            "Error al obtener regionals o localities para la provincia:",
            error
          );
        } finally {
          isLoadingLocalities.value = false;
          isLoadingRegionals.value = false;
        }
      } else {
        regionals.value = [];
        localities.value = [];
      }
    });

    watch(province_id, (newValue) => {
      if (newValue) delete formErrors.value.province_id;
    });

    watch(locality_id, (newValue) => {
      if (newValue) delete formErrors.value.locality_id;
    });

    watch(regional_id, (newValue) => {
      if (newValue) delete formErrors.value.regional_id;
    });

    watch(vehicle_type, (newValue) => {
      if (newValue) {
        delete formErrors.value.vehicle_type;
      } else {
        formErrors.value.vehicle_type = "El rubro es obligatorio.";
      }
    });

    watch(brands, (newValue) => {
      if (newValue && newValue.length > 0) {
        delete formErrors.value.brands;
      } else {
        formErrors.value.brands = "Debe seleccionar al menos una marca.";
      }
    });

    const handleVehicleTypeChange = async () => {
      if (!vehicle_type.value) {
        brandsOptions.value = [];
        brands.value = [];
        return;
      }

      try {
        brandsOptions.value = await fetchBrandsByVehiculeType(
          vehicle_type.value
        );

        brands.value = brands.value.filter(
          (brand) => brand.brand_vehicule_type === vehicle_type.value
        );
      } catch (error) {
        console.error("Error al obtener lista de marcas:", error);
        brandsOptions.value = [];
        brands.value = [];
      }
    };

    const buildFormData = () => ({
      cuit: habitualistaData.value.cuit,
      business_name: business_name.value,
      province_id: province_id.value,
      locality_id: locality_id.value,
      street_name: street_name.value,
      street_number: street_number.value,
      postal_code: postal_code.value,
      phone: phone.value,
      email: email.value,
      brands: brands.value.map((brand) => brand.id),
      regional_id: regional_id.value,
      website: website.value,
      sector: vehicle_type.value,
    });

    const handleSuccessResponse = (message) => {
      Swal.fire({
        icon: "success",
        title: "Operación exitosa",
        text: message,
      }).then(() =>
        goBack("ConcessionaireSubMenu", { subMenu: "casa-central" }, false)
      );
    };

    const handleErrorResponse = (error) => {
      console.error("Error en la operación de concesionario:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema en la operación. Inténtelo de nuevo.",
      });
    };

    const performConcessionaireOperation = async (formData) => {
      try {
        if (props.isAddBranch) {
          await addSucursal(habitualistaData.value.id, formData);
          handleSuccessResponse("La sucursal ha sido agregada exitosamente.");
        } else if (props.isEditing) {
          await updateConcessionaire(habitualistaData.value.id, formData);
          handleSuccessResponse(
            "El concesionario ha sido actualizado exitosamente."
          );
        } else {
          await createConcessionaire(formData);
          handleSuccessResponse(
            "El concesionario ha sido creado exitosamente."
          );
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };

    const validateForm = () => {
      const formData = {
        business_name: business_name.value,
        province_id: province_id.value,
        locality_id: locality_id.value,
        regional_id: regional_id.value,
        street_name: street_name.value,
        street_number: street_number.value,
        postal_code: postal_code.value,
        phone: phone.value,
        email: email.value,
        vehicle_type: vehicle_type.value,
        brands: brands.value.map((brand) => brand.id),
      };
      return validateConcessionaireForm(formData);
    };

    const submitForm = async () => {
      if (!validateForm()) {
        return;
      }
      const formData = buildFormData();
      isSubmitting.value = true;
      try {
        await performConcessionaireOperation(formData);
      } catch (error) {
        handleErrorResponse(error);
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      handleEmailInput,
      handleStreetNumberInput,
      validateForm,
      formErrors,
      validateNumericField,
      isSubmitting,
      habitualistaData,
      business_name,
      province_id,
      locality_id,
      street_name,
      street_number,
      postal_code,
      phone,
      email,
      website,
      brands,
      regional_id,
      submitForm,
      provinces,
      regionals,
      brandsOptions,
      localities,
      vehicle_type,
      vehicleTypes: vehicleTypesList,
      handleVehicleTypeChange,
      goBack,
      buttonContainerClass,
      isLoading,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.spinner-select {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  font-size: 0.875rem;
  color: #555;
}

.spinner-select i {
  margin-right: 5px;
}

.custom-width {
  width: 32%;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #333;
}

.spinner {
  margin-right: 8px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0056b3;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.form-row .col {
  flex: 1;
}

.separator-line {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.select-wrapper {
  position: relative;
}

.select-style {
  appearance: none;
  background-color: #f0f0f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-right: 25px;
}

.select-wrapper::after {
  content: "▾";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 0.875rem;
  color: #555;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.continue-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 0.875rem;
}

.small-button {
  font-size: 0.875rem;
  padding: 8px 16px;
}

.concessionaire-form-container {
  margin: 0 auto;
  max-width: 1320px;
  padding: 24px;
  border-radius: 8px;
}

.header {
  display: flex !important;
  align-items: center !important;
  gap: 10px;
}

.back-button {
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
}

.back-button {
  margin-right: auto;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .concessionaire-form-container {
    padding: 16px;
    max-width: 100%;
  }

  .header {
    flex-direction: column;
    text-align: center;
  }
  .form-row {
    flex-direction: column;
    gap: 10px;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

  .continue-button {
    width: 100%;
    max-width: 200px;
  }

  .form-group,
  .select-style {
    width: 100%;
  }

  .small-button {
    padding: 10px 16px;
  }
}

.required {
  color: #d9534f;
  font-weight: bold;
  margin-left: 5px;
}
</style>