<template>
  <div class="row mb-3 g-2 flex-nowrap">
    <!-- Provincia -->
    <div class="col">
      <select 
        :value="props.selectedProvince"
        @change="$emit('update:selectedProvince', $event.target.value)"
        class="form-select w-100"
      >
        <option value="">Todas las provincias</option>
        <option v-if="props.loadingProvinces" disabled>
          Cargando provincias...
        </option>
        <option
          v-for="province in props.provincesOptions"
          :key="province.id"
          :value="province.id"
        >
          {{ province.name }}
        </option>
      </select>
    </div>

    <!-- Localidad -->
    <div class="col">
      <select 
        :value="props.selectedLocality"
        @change="$emit('update:selectedLocality', $event.target.value)"
        class="form-select w-100"
      >
        <option value="">Todas las localidades</option>
        <option v-if="props.loadingLocalities" disabled>
          Cargando localidades...
        </option>
        <option
          v-for="locality in props.localitiesOptions"
          :key="locality.id"
          :value="locality.id"
        >
          {{ locality.name }}
        </option>
      </select>
    </div>

    <!-- Regional -->
    <div class="col">
      <select 
        :value="props.selectedRegional"
        @change="$emit('update:selectedRegional', $event.target.value)"
        class="form-select w-100"
      >
        <option value="">Todas las regionales</option>
        <option v-if="props.loadingRegionals" disabled>
          Cargando regionales...
        </option>
        <option
          v-for="regional in props.regionalsOptions"
          :key="regional.id"
          :value="regional.id"
        >
          {{ regional.name }}
        </option>
      </select>
    </div>

    <!-- Marca -->
    <div class="col">
      <select 
        :value="props.selectedBrand"
        @change="$emit('update:selectedBrand', $event.target.value)"
        class="form-select w-100"
      >
        <option value="">Todas las marcas</option>
        <option v-if="props.loadingBrands" disabled>Cargando marcas...</option>
        <option
          v-for="brand in props.brandsOptions"
          :key="brand.id"
          :value="brand.id"
        >
          {{ brand.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps({
  selectedProvince: String,
  provincesOptions: {
    type: Array,
    default: () => []
  },
  loadingProvinces: Boolean,

  selectedLocality: String,
  localitiesOptions: {
    type: Array,
    default: () => []
  },
  loadingLocalities: Boolean,

  selectedRegional: String,
  regionalsOptions: {
    type: Array,
    default: () => []
  },
  loadingRegionals: Boolean,

  selectedBrand: String,
  brandsOptions: {
    type: Array,
    default: () => []
  },
  loadingBrands: Boolean,
});

</script>