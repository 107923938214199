<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h1 class="header-title me-4">
      Concesionarios
      <span class="sub-title ml-2 text-muted">
        ({{ currentConcessionaireType }})
      </span>
    </h1>


    <div class="d-flex align-items-center gap-1">
     <button
        @click="toggleUsers"
        class="btn bg-light text-dark fs-6 px-3 py-2 border d-flex align-items-center"
        :class="withoutUsers ? 'border-primary text-primary' : ''"
      >
        <i class="fas fa-user-shield me-2 text-primary"></i>
        Sin Usuarios
        : {{ totalWithoutAdministrators }}
      </button>

      <button
        @click="toggleAdministrators"
        class="btn bg-light text-dark fs-6 px-3 py-2 border d-flex align-items-center"
        :class="withoutAdministrators ? 'border-primary text-primary' : ''"
      >
        <i class="fas fa-users-slash me-2 text-primary"></i>
        Sin Admin
        :{{ totalWithoutUsers }}
      </button>
      <span class="badge bg-light text-dark fs-6 px-3 py-2 border">
        <i class="fas fa-database me-2 text-primary"></i>
        Total: {{ totalRegisters }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  currentConcessionaireType: String,
  totalRegisters: Number,
  totalWithoutAdministrators: Number,
  totalWithoutUsers: Number,

  withoutUsers: Boolean,
  withoutAdministrators: Boolean,
});

const emit = defineEmits([
  "update:withoutUsers",
  "update:withoutAdministrators",
]);

const toggleUsers = () => {
  emit("update:withoutUsers", !props.withoutUsers);
};

const toggleAdministrators = () => {
  emit("update:withoutAdministrators", !props.withoutAdministrators);
};
</script>
<style>
.no-hover-effect:hover, 
.no-hover-effect:focus {
  background-color: var(--bs-light) !important;
  border-color: var(--bs-border-color) !important;
}
</style>