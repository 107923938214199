<template>
  <div class="container p-5">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="mb-0" style="font-size: 2rem">Vistas</h1>
      <div class="d-flex align-items-center mt-6 mt-md-0">
        <button @click="openForm()" class="btn btn-primary">Nueva Vista</button>
      </div>
    </div>

    <div class="mb-3">
      <input
        type="text"
        v-model="searchTerm"
        class="form-control"
        placeholder="Buscar..."
      />
    </div>

    <div v-if="loading" class="text-center">Cargando...</div>
    <div v-else>
      <ViewsTable
        :views="views"
        :table-headers="tableHeaders"
        :table-columns="tableColumns"
        @edit-view="openForm"
        @delete-view="deleteView"
        @show-item="showItem"
      />

      <Pagination
        :current-page="currentPage"
        :last-page="lastPage"
        :has-more-pages="hasMorePages"
        @fetch-views="fetchViews"
      />
    </div>

    <CreateViewModal
      :isFormOpen="isFormOpen"
      @close="closeForm"
      @view-created="fetchViews"
      @view-created-success="handleSuccess"
      @view-updated-success="handleSuccess"
      @error="handleError"
      :viewToEdit="viewToEdit"
      :currentUrl="viewToEdit ? viewToEdit.url : ''"
    />

    <ViewModalsComponent
      :selected-item="selectedItem"
      :selected-type="selectedType"
      @close-item="closeItem"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { ref, computed, onMounted, watch } from "vue";
import CreateViewModal from "./CreateViewModal.vue";
import ViewModalsComponent from "@/components/vista/ViewModalsComponent.vue";
import ViewsTable from "@/views/vistas/ViewsTable.vue";
import Pagination from "@/components/data-display/DataPagination.vue";
import { fetchViewsApi, deleteView as deleteViewApi } from "@/services/api/viewService.js";
import userConfirmation from "@/utils/userConfirmation.js";

export default {
  components: {
    CreateViewModal,
    ViewModalsComponent,
    ViewsTable,
    Pagination,
  },
  setup() {
    const views = ref([]);
    const searchTerm = ref("");
    const orderByMethod = ref("asc");
    const perPage = ref(10);
    const currentPage = ref(1);
    const lastPage = ref(1);
    const isFormOpen = ref(false);
    const loading = ref(false);
    const hasMorePages = computed(() => currentPage.value < lastPage.value);
    const viewToEdit = ref(null);
    const selectedItem = ref(null);
    const selectedType = ref(null);

    const tableHeaders = ref(["Nombre", "URL", "Creado", "Actualizado", "Opciones"]);
    const tableColumns = ref([
      { key: "name", isDate: false },
      { key: "url", isDate: false },
      { key: "created_at", isDate: true },
      { key: "updated_at", isDate: true },
    ]);

    const buildQueryParams = (overrides = {}) => ({
      search: searchTerm.value || null,
      orderByMethod: orderByMethod.value,
      perPage: perPage.value,
      page: currentPage.value,
      ...overrides,
    });

    const fetchViews = async (page = 1) => {
      loading.value = true;
      try {
        const params = buildQueryParams({ page });
        const data = await fetchViewsApi(params);
        views.value = data.data || [];
        currentPage.value = data.meta.current_page || 1;
        lastPage.value = data.meta.last_page || 1;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          confirmButtonText: "Aceptar",
        });
      } finally {
        loading.value = false;
      }
    };

    watch([searchTerm, orderByMethod, perPage], () => {
      fetchViews(1);
    });

    const showItem = (view, type) => {
      selectedItem.value = view;
      selectedType.value = type;
    };

    const closeItem = () => {
      selectedItem.value = null;
      selectedType.value = null;
    };

    const openForm = () => {
      Swal.fire({
        icon: "info",
        title: "En mantenimiento",
        text: "Esta funcionalidad está fuera de servicio por mantenimiento.",
        confirmButtonText: "Entendido",
      });
    };

    const closeForm = () => {
      isFormOpen.value = false;
    };

    const deleteView = async (url) => {
      const confirmed = await userConfirmation(
        `¿Está seguro de eliminar la vista seleccionada?`
      );
      if (!confirmed) return;

      try {
        await deleteViewApi(url);
        fetchViews(currentPage.value);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          confirmButtonText: "Aceptar",
        });
      }
    };

    onMounted(() => {
      fetchViews();
    });

    return {
      viewToEdit,
      views,
      searchTerm,
      isFormOpen,
      loading,
      currentPage,
      lastPage,
      hasMorePages,
      selectedItem,
      selectedType,
      fetchViews,
      showItem,
      closeItem,
      openForm,
      closeForm,
      deleteView,
      tableHeaders,
      tableColumns,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 100% !important;
}
</style>
