<template>
  <div v-if="isOpen" class="modal-backdrop fade show"></div>
  
  <div 
    class="modal fade" 
    :class="{show: isOpen}" 
    tabindex="-1" 
    v-show="isOpen"
    style="display: block;"
  >
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content" v-if="safeConcessionaire">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">Usuarios de {{ safeConcessionaire.business_name }}</h5>
          <button 
            type="button" 
            class="close text-white" 
            @click="closeModal"
            aria-label="Cerrar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <div class="modal-body">
          <!-- Administradores -->
          <div class="mb-5">
            <h5 class="font-weight-bold mb-4 border-bottom pb-2">Administradores</h5>
            <UserTable 
              v-if="safeConcessionaire.administrators.length > 0"
              :users="safeConcessionaire.administrators"
            />
            <EmptyMessage v-else/>
          </div>

          <!-- Usuarios -->
          <div class="mb-4">
            <h5 class="font-weight-bold mb-4 border-bottom pb-2">Usuarios</h5>
            <UserTable 
              v-if="safeConcessionaire.users.length > 0"
              :users="safeConcessionaire.users"
            />
            <EmptyMessage v-else/>
          </div>
        </div>
        
        <div class="modal-footer">
          <button 
            type="button" 
            class="btn btn-secondary" 
            @click="closeModal"
          >
            <i class="fas fa-times mr-2"></i>Cerrar
          </button>
        </div>
      </div>
      
      <!-- Estado de carga -->
      <div v-else class="modal-content">
        <div class="modal-body text-center py-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
          <p class="mt-2">Cargando datos...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserTable from './UserTable.vue'
import EmptyMessage from './EmptyMessage.vue'

export default {
  components: {
    UserTable,
    EmptyMessage
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
   concessionaire: {
      type: Object,
      required: true,
      default: () => ({
        business_name: '',
        administrators: [],
        users: []
      })
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  },
  computed: {
    safeConcessionaire() {
      return this.concessionaire ? {
        business_name: this.concessionaire.business_name || 'Concesionario',
        administrators: Array.isArray(this.concessionaire.administrators)
          ? this.concessionaire.administrators
          : [],
        users: Array.isArray(this.concessionaire.users)
          ? this.concessionaire.users
          : []
      } : null;
    }
  },
}
</script>

<style scoped>
.modal-content {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}

.modal-body {
  overflow-y: auto;
  flex: 1;
  padding: 1.5rem;
}

.modal-header h5 {
  font-size: 1.25rem;
}

.border-bottom {
  border-color: #dee2e6 !important;
}
</style>